import React, { useEffect, useState } from 'react'
import '../Products/Product.css';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Braid_rugsData from './Braid_rugsData';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ProductRange from '../extracomponents/ProductRange';
import FooterContact from '../extracomponents/FooterContact';
import ProductHeader from '../extracomponents/ProductHeader';
import Productcategory from '../extracomponents/Productcategory';
import Footer from '../Footer/Footer';

const Braid_rugs = () => {

    const [spinner, setspinner] = useState(true);
    const [rugdata, setrugdata] = useState(false);



    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
            setspinner(false)
        }, 500);

    }, []);


    const navbraanimate = () => {
        // window.addEventListener("scroll",function(){
        //     const header = document.querySelector('header');
        //     header.classList.toggle('sticky',window.scrollY > 0);
        // });
        const navigation = document.querySelector('nav');

        document.querySelector('.toggle').onclick = function () {
            this.classList.toggle('active');
            navigation.classList.toggle('active');
        }
    }
    const [mydata, setPassdata] = useState(0, 3);
    const password = "senorita";
    const ddd1 = Braid_rugsData.slice(0, 3).map((item) => (

        <>
            <a className='nav-link' href={item.imgsrc}
                data-lightbox="models" data-title={item.code}>

                <LazyLoadImage effect="blur" src={item.imgsrc} />

                <div className="text-center d-flex align-items-center justify-content-center">
                    <span className='px-5  text-white' style={{ backgroundColor: '#636e72' }}>{item.code}</span>

                </div>

            </a>

        </>

    ))


    // const slice = ""
    // const slice1 = "0"
    // var mydata = ""
    const showproduct = (item) => {
        item.preventDefault();
        let inputpassword = document.querySelector('#passinput').value;
        if (password == inputpassword) {
            alert("successful")
            // mydata = ddd;
            // setPassdata(mydata);
            setrugdata(true)

        } else {
            alert('password wrong')
            // setPassdata(mydata);
            setrugdata(false)
            // mydata = ddd1
        }
    }
    // Navbar Script==

    // Cars Animation Script==
    // function moveF() {
    //     document.querySelector(".card-container").style.width = "98vw";
    //     document.querySelector("#card1").style.left = "20vw";
    //     document.querySelector("#card2").style.left = "40vw";
    //     document.querySelector("#card3").style.left = "60vw";
    //     document.querySelector("#card4").style.left = "80vw";
    //     var nodes = document
    //         .querySelector(".card-container")
    //         .getElementsByTagName("div");
    //     for (var i = 0; i < nodes.length; i++) {
    //         nodes[i].style.transform = "translate(-50%, -50%)";
    //     }
    //     document.querySelector("#btn").style.display = "block";
    // }
    // function moveF2() {
    //     document.querySelector(".card-container").style.width = "100vw";
    //     document.querySelector("#card1").style.left = "20vw";
    //     document.querySelector("#card1").style.top = "10vh";
    //     document.querySelector("#card2").style.left = "80vw";
    //     document.querySelector("#card2").style.top = "10vh";
    //     document.querySelector("#card3").style.left = "20vw";
    //     document.querySelector("#card3").style.top = "50vh";
    //     document.querySelector("#card4").style.left = "80vw";
    //     document.querySelector("#card4").style.top = "50vh";
    //     var nodes = document
    //         .querySelector(".card-container")
    //         .getElementsByTagName("div");
    //     for (var i = 0; i < nodes.length; i++) {
    //         nodes[i].style.transform = "translate(-50%, -50%)";
    //     }
    //     document.querySelector("#btn").style.display = "block";
    // }
    // function moveB() {
    //     document.querySelector("#card1").style.left = "50%";
    //     document.querySelector("#card1").style.top = "50%";
    //     document.querySelector("#card2").style.left = "50%";
    //     document.querySelector("#card2").style.top = "50%";
    //     document.querySelector("#card3").style.left = "50%";
    //     document.querySelector("#card3").style.top = "50%";
    //     document.querySelector("#card4").style.left = "50%";
    //     document.querySelector("#card4").style.top = "50%";
    //     document.querySelector("#card1").style.transform =
    //         "translate(-50%,-50%) rotate(10deg)";
    //     document.querySelector("#card2").style.transform =
    //         "translate(-50%,-50%) rotate(5deg)";
    //     document.querySelector("#card3").style.transform =
    //         "translate(-50%,-50%) rotate(-5deg)";
    //     document.querySelector("#card4").style.transform =
    //         "translate(-50%,-50%) rotate(-10deg)";
    //     document.querySelector("#btn").style.display = "none";
    // }
    // const mediaQuery = window.matchMedia("(max-width: 650px)");
    // if (mediaQuery.matches) {
    //     var element = document.querySelector(".card-container");
    //     element.removeAttribute("onclick");
    //     element.setAttribute("onclick", "moveF2()");
    // }
    // Cars Animation Script==

    return (

        <>

            {
                !spinner && <div>


                    {/* Sticky Navbar With Animation Effect */}
                    <header className="productheader" style={{ zIndex: '1' }}>
                        <img src={require('../Products/images/ll.png').default} className="banner" alt="" />
                        <a href="#" className="logo text-center">BRAID RUGS</a>
                        <div className="bg" style={{ backgroundColor: 'rgba(0,0,0,0.3)', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}>

                        </div>
                        <div class="toggle" onClick={navbraanimate}></div>
                        <ProductHeader />
                    </header>
                    {/* Sticky Navbar With Animation Effect */}


                    {/* Gallery==== */}
                    <section className="text-center " style={{ marginTop: '30vh' }}>
                        <h2>Our Gallery</h2>
                    </section>
                    <div class="container py-5">
                        <div class="gallery">

                            { rugdata ? Braid_rugsData && Braid_rugsData.map((item) => (

                                <>
                                    <a className='nav-link' href={item.imgsrc}
                                        data-lightbox="models" data-title={item.code}>

                                        <LazyLoadImage effect="blur" src={item.imgsrc} />

                                        <div className="text-center d-flex align-items-center justify-content-center">
                                            <span className='px-5  text-white' style={{ backgroundColor: '#636e72' }}>{item.code}</span>

                                        </div>

                                    </a>

                                </>

                            )):(
                                Braid_rugsData && Braid_rugsData.slice(0,3).map((item) => (

                                    <>
                                        <a className='nav-link' href={item.imgsrc}
                                            data-lightbox="models" data-title={item.code}>
    
                                            <LazyLoadImage effect="blur" src={item.imgsrc} />
    
                                            <div className="text-center d-flex align-items-center justify-content-center">
                                                <span className='px-5  text-white' style={{ backgroundColor: '#636e72' }}>{item.code}</span>
    
                                            </div>
    
                                        </a>
    
                                    </>
    
                                ))
                            )
                          


                        }

                        </div>

                    </div>
                    <div className='d-flex justify-content-center py-5'>
                        <form class="d-flex">
                            <input class="form-control me-2" type="search" id='passinput' placeholder="Enter Password" aria-label="Search" />
                            <button class="btn btn-success" onClick={showproduct}>Submit</button>
                        </form>
                    </div>
                    {/* Gallery==== */}






                    {/* Cards Animated=== */}

                    {/* {Scroll Bar Cards}    */}
                    {/* <ProductRange/> */}
                    <Productcategory />

                    {/* {Scroll Bar Cards}    */}

                    {/* Cards Animated=== */}






                    {/* Contact=== */}
                    <FooterContact />

                    {/* Contact=== */}
                    <Footer />




                </div>

            }


        </>

    )
}
export default Braid_rugs
