export default [
    {
        Id: 1,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(1).jpg").default,
        spinner:"text-primary",
        code:'VDPJ - 308',
    },
    {
        Id: 2,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(2).jpg").default,
        spinner:"text-secondary",
        code:'VDPJ - 331 ',
    },
    {
        Id: 3,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(3).jpg").default,
        spinner:"text-success",
        code:'VDPJ - 347 ',
    },
    {
        Id: 4,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(4).jpg").default,
        spinner:"text-danger",
        code:'VDPJ - 363 ',
    },
    {
        Id: 5,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(5).jpg").default,
        spinner:"text-warning",
        code:'VDPJ - 368 ',
    },
    {
        Id: 6,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(6).jpg").default,
        spinner:"text-info",
        code:'VDPJ - 723 ',
    },
    {
        Id: 7,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(7).jpg").default,
        spinner:"text-light",
        code:'VDPJ - 751 ',
    },
    {
        Id: 8,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(8).jpg").default,
        spinner:"text-dark",
        code:'VDPJ - 753 ',
    },
    {
        Id: 9,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(9).jpg").default,
        spinner:"text-warning",
        code:'VDPJ - 754 ',
    },
    {
        Id: 10,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(10).jpg").default,
        spinner:"text-success",
        code:'VDPJ - 767 ',
    },
    {
        Id: 11,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(11).jpg").default,
        spinner:"text-info",
        code:'VDPJ - 1001',
    },
    {
        Id: 12,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(12).jpg").default,
        spinner:"text-info",
        code:'VDPJ - 10033 ',
    },
    {
        Id: 13,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(13).JPG").default,
        spinner:"text-info",
        code:'VDPJ-302(2) ',
    },
    {
        Id: 14,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(14).JPG").default,
        spinner:"text-info",
        code:'VDPJ-386 ',
    },
    {
        Id: 15,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(15).jpg").default,
        spinner:"text-info",
        code:'VDPJ-398 ',
    },
    {
        Id: 16,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(16).JPG").default,
        spinner:"text-info",
        code:'VDPJ-402 ',
    },
    {
        Id: 17,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(17).JPG").default,
        spinner:"text-info",
        code:'VDPJ-508 ',
    },
    {
        Id: 18,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(18).JPG").default,
        spinner:"text-info",
        code:'VDPJ-550',
    },
    {
        Id: 19,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(19).JPG").default,
        spinner:"text-info",
        code:'VDPJ-607',
    },
    // {
    //     Id: 20,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(20).JPG").default,
    //     spinner:"text-info",
    //     code:'VDPJ-607 ',
    // },
    {
        Id: 21,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(21).JPG").default,
        spinner:"text-info",
        code:'VDPJ-607 ',
    },
    {
        Id: 22,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(22).JPG").default,
        spinner:"text-info",
        code:'VDPJ-654 ',
    },
    {
        Id: 23,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(23).JPG").default,
        spinner:"text-info",
        code:'VDPJ-655 ',
    },
    {
        Id: 24,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(24).JPG").default,
        spinner:"text-info",
        code:'VDPJ-659 ',
    },
    {
        Id: 25,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(25).JPG").default,
        spinner:"text-info",
        code:'VDPJ-680 ',
    },
    {
        Id: 26,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(26).JPG").default,
        spinner:"text-info",
        code:'VDPJ-683 ',
    },
    {
        Id: 27,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(27).JPG").default,
        spinner:"text-info",
        code:'VDPJ-683',
    },
    {
        Id: 28,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(28).jpg").default,
        spinner:"text-info",
        code:'VDPJ-686(2)',
    },
    {
        Id: 29,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(29).JPG").default,
        spinner:"text-info",
        code:'VDPJ-686',
    },
    {
        Id: 30,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(30).JPG").default,
        spinner:"text-info",
        code:'VDPJ-690 ',
    },
    {
        Id: 31,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(31).JPG").default,
        spinner:"text-info",
        code:'VDPJ-695',
    },
    {
        Id: 32,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(32).JPG").default,
        spinner:"text-info",
        code:'VDPJ-697 ',
    },
    {
        Id: 33,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(33).JPG").default,
        spinner:"text-info",
        code:'VDPJ-697',
    },
    {
        Id: 34,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(34).JPG").default,
        spinner:"text-info",
        code:'VDPJ-698',
    },
    {
        Id: 35,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(35).JPG").default,
        spinner:"text-info",
        code:'VDPJ-699 ',
    },
    {
        Id: 36,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(36).JPG").default,
        spinner:"text-info",
        code:'VDPJ-700 ',
    },
    {
        Id: 37,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(37).JPG").default,
        spinner:"text-info",
        code:'VDPJ-702',
    },
    {
        Id: 38,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(38).jpg").default,
        spinner:"text-info",
        code:'VDPJ-740 ',
    },
    {
        Id: 39,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(39).JPG").default,
        spinner:"text-info",
        code:'VDPJ-748 ',
    },
    {
        Id: 40,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(40).jpg").default,
        spinner:"text-info",
        code:'VDPJ-772 ',
    },
    {
        Id: 41,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(41).jpg").default,
        spinner:"text-info",
        code:'VDPJ-783 ',
    },
    {
        Id: 42,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(42).jpg").default,
        spinner:"text-info",
        code:'VDPJ-784 ',
    },
    {
        Id: 43,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(43).jpg").default,
        spinner:"text-info",
        code:'VDPJ-1671 ',
    },
    // {
    //     Id: 44,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(44).JPG").default,
    //     spinner:"text-info",
    //     code:'VDPJ-1671 ',
    // },
    {
        Id: 45,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(45).JPG").default,
        spinner:"text-info",
        code:'VDPJ-1672 ',
    },
    {
        Id: 46,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(46).jpg").default,
        spinner:"text-info",
        code:'VDPJ-11018  ',
    },
    // {
    //     Id: 47,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(47).jpg").default,
    //     spinner:"text-info",
    //     code:'VDPJ-11018  ',
    // },
    {
        Id: 48,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(48).JPG").default,
        spinner:"text-info",
        code:'VDPJ-11018 ',
    },
    {
        Id: 49,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(49).jpg").default,
        spinner:"text-info",
        code:'VDPJ-11031 ',
    },
    // {
    //     Id: 50,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(50).JPG").default,
    //     spinner:"text-info",
    //     code:'VDPJ-11031 ',
    // },
    {
        Id: 51,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(51).JPG").default,
        spinner:"text-info",
        code:'VDPJ-11031 ',
    },
    {
        Id: 52,
        imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(52).jpg").default,
        spinner:"text-info",
        code:'VDPJ - 34 ',
    },
    // {
    //     Id: 53,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(53).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 54,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(54).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 55,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(55).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 56,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(56).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 57,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(57).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 58,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(58).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 59,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(59).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 60,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(60).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 61,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(61).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 62,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(62).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 63,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(63).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 64,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(64).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 65,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(65).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 66,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(66).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 67,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(67).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 68,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(68).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 69,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(69).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 70,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(70).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 71,
    //     imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/(71).jpg").default,
    //     spinner:"text-info",
    // },

   

];
