export default [
    {
        Id: 1,
        imgsrc: require("../images/ACCESSORIES/CUSHIONS/(1).jpg").default,
        spinner:"text-primary",
        code:'VDCS-03 ',
    },
    {
        Id: 2,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(2).jpg").default,
        spinner:"text-secondary",
        code:'VDCS-04 ',
    },
    {
        Id: 3,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(3).jpg").default,
        spinner:"text-success",
        code:'VDCS-05 ',
    },
    {
        Id: 4,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(4).jpg").default,
        spinner:"text-danger",
        code:'VDCS-06 ',
    },
    {
        Id: 5,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(5).jpg").default,
        spinner:"text-warning",
        code:'VDCS-11 ',
    },
    {
        Id: 6,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(6).jpg").default,
        spinner:"text-info",
        code:'VDCS-32 ',
    },
    {
        Id: 7,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(7).jpg").default,
        spinner:"text-light",
        code:'VDCS-34',
    },
    {
        Id: 8,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(8).jpg").default,
        spinner:"text-dark",
        code:'VDCS-35 ',
    },
    {
        Id: 9,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(9).jpg").default,
        spinner:"text-warning",
        code:'VDCS-37',
    },
    {
        Id: 10,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(10).jpg").default,
        spinner:"text-success",
        code:'VDCS-39 ',
    },
    {
        Id: 11,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(11).jpg").default,
        spinner:"text-info",
        code:'VDCS-41',
    },
    {
        Id: 12,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(12).jpg").default,
        spinner:"text-info",
        code:'VDCS-111 ',
    },
    {
        Id: 13,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(13).jpg").default,
        spinner:"text-info",
        code:'VDCS-112 ',
    },
    {
        Id: 14,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(14).jpg").default,
        spinner:"text-info",
        code:'VDCS-113',
    },
    {
        Id: 15,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(15).jpg").default,
        spinner:"text-info",
        code:'VDCS-116 ',
    },
    {
        Id: 16,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(16).jpg").default,
        spinner:"text-info",
        code:'VDCS-117 ',
    },
    {
        Id: 17,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(17).jpg").default,
        spinner:"text-info",
        code:'VDCS-118 ',
    },
    {
        Id: 18,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(18).jpg").default,
        spinner:"text-info",
        code:'VDCS-119',
    },
    {
        Id: 19,
        imgsrc: require( "../images/ACCESSORIES/CUSHIONS/(19).jpg").default,
        spinner:"text-info",
        code:'VDCS-120 ',
    },
   

];
