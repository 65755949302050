import React from 'react';
import { Link } from 'react-router-dom';
import "./footer.css"

const Footer = () => {
    return (
        <div>


            {/* Footer==== */}

            <div className="row footer gx-0">
                <div className="col-md-4 col-12 " style={{ padding: '10px 30px' }}>
                    <img src={require('../Home/images/Vinilogo.png').default}   width="30%" height="100px" alt="" />
                    <div className="upper_text mb-3">
                        <p class="my-0">WhatsApp us at,</p>
                        <b style={{ fontSize: '1.6rem' }}>+91-9991000004</b>
                    </div>
                    <div className="lower_text">
                        <p class="my-0">Follow us on,</p>
                        <a href="https://www.facebook.com/vinidecorindia/"><i class="fab fa-facebook-square me-2" style={{ fontSize: '2rem', color: '#85523A' }}></i></a>
                        <a href="https://in.linkedin.com/in/nitin-garg-723122ab"><i class="fab fa-linkedin me-2" style={{ fontSize: '2rem', color: '#85523A' }}></i></a>
                        <a href="https://www.instagram.com/vinidecor.in/?hl=en"><i class="fab fa-instagram-square me-2" style={{ fontSize: '2rem', color: '#85523A' }}></i></a>
                        <a href="https://mobile.twitter.com/vinirugs"><i class="fab fa-twitter-square me-2" style={{ fontSize: '2rem', color: '#85523A' }}></i></a>
                        <a href="https://pin.it/2A9LNu2"><i class="fa-brands fa-pinterest-square me-2" style={{ fontSize: '2rem', color: '#85523A' }}></i></a>
                        {/* <a href="https://pin.it/7oKAvQu"><i class="fab fa-pinterest-square me-2" style={{ fontSize: '2rem', color: '#85523A' }}></i></a> */}
                        {/* <i class="fab fa-pinterest-square"></i> */}
                    </div>
                </div>
                <div className="col-md-4 col-12" style={{ padding: '10px', }}>

                    <div className="row">

                        <div className="col-lg-6">
                            <h4 style={{ textAlign: 'left', fontWeight: '800' }}>Our Company</h4>
                            <ul className="list-group list-group-flush">
                                {/* <li

                                    className="list-group-item">
                                    <li><Link to="/">Home</Link></li>
                                </li>
                                <li
                                    className="list-group-item">
                                    <li><Link to="/aboutus">About Us</Link></li>
                                </li>
                                <li
                                    className="list-group-item">
                                    <li><Link to="/contact">Contact Us</Link></li>
                                </li> */}
                                
                                <li
                                    className="list-group-item">
                                    <li><Link to="/whats-new">What's New</Link></li>
                                </li>
                                <li
                                    className="list-group-item">
                                    <li><Link to="/infrastructure">Infrastructure</Link></li>
                                </li>
                                <li
                                    className="list-group-item">
                                    <li><Link to="/certification">Certificates & Awards</Link></li>
                                </li>
                                <li
                                    className="list-group-item">
                                    <li><Link to="/client">Client</Link></li>
                                </li>
                                <li
                                    className="list-group-item">
                                    <li><Link to="/exhibition">Exhibition</Link></li>
                                </li>
                                <li
                                    className="list-group-item">
                                    <li><Link to="/feedback">Feedback</Link></li>
                                </li>
                                <li
                                    className="list-group-item">
                                    <li><Link to="/terms">Terms & Conditions</Link></li>
                                </li>
                                {/* <li
                                    className="list-group-item">
                                    <li><Link to="/factory">Factory</Link></li>
                                </li> */}

                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <h4 style={{ textAlign: 'left', fontWeight: '800' }}>Our Products</h4>
                            <ul className="list-group list-group-flush">

                                <li
                                    className="list-group-item">
                                    <Link to="/braid_rugs">Braid Rugs </Link>
                                </li>
                                <li
                                    className="list-group-item">
                                    <Link to="/handloom_carpet">Texture Carpets</Link>
                                </li>
                                <li
                                    className="list-group-item">
                                    <Link to="/handtufted-carpet">Tufted Carpets</Link>
                                </li>
                                <li
                                    className="list-group-item">
                                    <Link to="/handwoven-pitloom">Pitloom Rugs</Link>
                                </li>
                                <li
                                    className="list-group-item">
                                    <Link to="/handwoven-punja">Punja Weaves</Link>
                                </li>
                                <li
                                    className="list-group-item">
                                    <Link to="/cushion_acces">Cushions</Link>
                                </li>
                                <li
                                    className="list-group-item">
                                    <Link to="/poufs">Poufs</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12" style={{ padding: '10px' }}>
                    <h3 style={{ textAlign: 'left', fontWeight: '400' }}>Contact Our Sales Team</h3>
                    <div style={{ marginTop: '5px', marginBottom: '5px' }}>

                        <div className="row">
                            <div className="col-1">
                                <i style={{ fontSize: '18px' }} className="fas fa-map-marker-alt"></i>
                            </div>
                            <div className="col-10 footercontact">
                                <p>
                                    We would like to inform you of new special product launches & offers.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                            {/* <div className="col-lg-12 mb-4">
                                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                            </div> */}
                            <div className="col-lg-12 text-white">
                                {/* <a href="#" class="form-control btn text-white" style={{ backgroundColor: '#85523A' }} placeholder="Search" aria-label="Search">Submit</a> */}
                                <Link to="/contact" className="btn1 btn-4 w-100"><span>Contact Us</span></Link>
                            </div>
                        </div>
                        <span id="siteseal">
                            <script async type="text/javascript"
                                src="https://seal.godaddy.com/getSeal?sealID=4ll3mPrhFDns3bEYCW4VAOULweGL4GFtAscaFjJY3WtQZ106xIMBcHSW7xXk"></script>
                        </span>
                    </div>
                </div>

            </div>
            <div className="row gx-0" style={{backgroundColor:"#85523A"}}>
                <div className="col-12 text-center text-white">Designed by  <a className=' text-white' href="https://digisidekick.com/"> Digi Sidekick</a>
                </div>
            </div>
            <div id="whatsapp" class="whatsapp">
                <a href="https://wa.link/a9hbv1" target="_blank" title="Whatsapp" data-bs-toggle="tooltip" data-bs-placement="top">
                    <i class="fab fa-whatsapp"></i>
                </a>
            </div>

            {/* Footer==== */}

            {/* <div id="feedback-form-wrapper">
                <div id="floating-icon">
                    <button type="button" class="btn btn-primary btn-sm rounded-0" data-toggle="modal" data-target="#exampleModal">
                        Feedback
                    </button>

                </div> */}

                {/* feedback form */}
                {/* <div id="feedback-form-modal">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Feedback Form</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
              
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">How likely you would like to recommand us to your friends?</label>
                <div class="rating-input-wrapper d-flex justify-content-between mt-2">
                  <label><input type="radio" name="rating" /><span class="border rounded px-3 py-2">1</span></label>
                  <label><input type="radio" name="rating" /><span class="border rounded px-3 py-2">2</span></label>
                  <label><input type="radio" name="rating" /><span class="border rounded px-3 py-2">3</span></label>
                  <label><input type="radio" name="rating" /><span class="border rounded px-3 py-2">4</span></label>
                  <label><input type="radio" name="rating" /><span class="border rounded px-3 py-2">5</span></label>
                  <label><input type="radio" name="rating" /><span class="border rounded px-3 py-2">6</span></label>
                  <label><input type="radio" name="rating" /><span class="border rounded px-3 py-2">7</span></label>
                  <label><input type="radio" name="rating" /><span class="border rounded px-3 py-2">8</span></label>
                  <label><input type="radio" name="rating" /><span class="border rounded px-3 py-2">9</span></label>
                  <label><input type="radio" name="rating" /><span class="border rounded px-3 py-2">10</span></label>
                </div>
                <div class="rating-labels d-flex justify-content-between mt-1">
                  <label>Very unlikely</label>
                  <label>Very likely</label>
                </div>
              </div>
              <div class="form-group">
                <label for="input-one">What made you leave us so early?</label>
                <input type="text" class="form-control" id="input-one" placeholder=""/>
              </div>
              <div class="form-group">
                <label for="input-two">Would you like to say something?</label>
                <textarea class="form-control" id="input-two" rows="3"></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div> */}
            {/* </div> */}


        </div>
    )
}
export default Footer;