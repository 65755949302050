// import React, { useEffect } from 'react';
import React from 'react';
import "../../src/index.css";
import "../Home/review.css";
import "../Home/Home.css";
import "../Home/Slider/jquery.easy_slides.css";
import { Link } from 'react-router-dom';
import { HeaderDropDown } from '../extracomponents/HeaderDropDown';


const Header = () => {


    const btnn = () => {
        // e.persist();
        let btn = document.querySelector('.menu_btn');
        let navbar = document.querySelector('.nav_icon');
        // document.querySelector('.menu_btn').onclick = function () {
        navbar.classList.toggle('mobile');
        btn.classList.toggle('fa-times');
        // }
    }



    // Navbar Script==
    return (
        <div>

            {/* <!-- <Header Start============ --> */}
            <header>
                <nav className="header_box px-5 bg-light">
                    <div className="logo">
                        <h1 className="text-white">
                            <img src={require('../Home/images/Vinilogo.png').default} width="100%" height="100px" alt="" />
                        </h1>
                    </div>
                    <div className="nav_icon container-fluid">
                        <li><Link to="/">Home</Link></li>
                        <HeaderDropDown />
                        {/* ====================== Mobile Drop */}
                        <a data-toggle="collapse" href="#Products" role="button" class="d-lg-none" aria-expanded="false"
                            aria-controls="collapseExample">Products </a>
                        <div style={{ paddingLeft: '5px' }} class="collapse d-lg-none" id="Products">
                            <a style={{ color: '#fff', fontSize: '15px', fontWeight: '200', paddingLeft: '35px' }} data-toggle="collapse" href="#drop1" role="button" aria-expanded="false"
                                aria-controls="collapseExample">Rugs & carpet&nbsp;&nbsp; <i class="fas fa-chevron-down"></i></a>
                            <div style={{ paddingLeft: '15px' }} class="collapse" id="drop1">
                                <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/braid_rugs">Braid Rugs</Link>
                                <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/handloom_carpet">Handloom Texture Carpets</Link>
                                <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/handtufted-carpet">Handmade Tufted Carpets</Link>
                                <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/handwoven-pitloom">Handwoven Pitloom Rugs</Link>
                                <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/handwoven-punja">Handwoven Punja Weaves</Link>
                                {/* <Link style={{color: '#fff', fontSize: '13px',fontWeight:'100'}} class="dropdown-item" to="/handloom_carpet">Handloom Texture Carpets</Link> */}

                            </div><br />

                            <a style={{ color: '#fff', fontSize: '15px', fontWeight: '200', paddingLeft: '35px' }} data-toggle="collapse" href="#drop3" role="button" aria-expanded="false"
                                aria-controls="collapseExample">Accessories &nbsp;&nbsp;<i class="fas fa-chevron-down"></i></a>
                            <div style={{ paddingLeft: '15px' }} class="collapse" id="drop3">
                                {/* <Link style={{color: '#fff', fontSize: '13px',fontWeight:'100'}} class="dropdown-item" >Leather Flooring</Link> */}
                                <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/cushion_acces">Cushions</Link>
                                <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/poufs">Poufs</Link>
                                {/* <Link style={{color: '#fff', fontSize: '13px',fontWeight:'100'}} class="dropdown-item"  to="/throws">Throws</Link> */}
                            </div><br />
                            <a style={{ color: '#fff', fontSize: '15px', fontWeight: '200', paddingLeft: '35px' }} data-toggle="collapse" href="#drop2" role="button" aria-expanded="false"
                                aria-controls="collapseExample">Other Info &nbsp;&nbsp;<i class="fas fa-chevron-down"></i></a>
                            <div style={{ paddingLeft: '15px' }} class="collapse" id="drop2">
                                <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/whats-new">What's New</Link>
                                <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/infrastructure">Infrastructure</Link>
                                <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/carpet-care">Carpet Care</Link>
                                <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/exhibition">Exhibition</Link>
                            </div><br />

                        </div>
                        {/* ====================== Mobile Drop */}
                        <li><Link to="/aboutus">About Us</Link></li>
                        {/* <HeaderDropDown/> */}
                        <li><Link to="/contact">Contact Us</Link></li>
                        {/* <li><Link to="/login">Login</Link></li> */}
                    </div>
                    <div className="mobile_number text-white d-none d-md-block">
                        <b><Link href="#"> <span style={{ color: "#D19797" }}>+91-999-100-0004</span></Link></b>
                    </div>

                    <i className="menu_btn fas fa-bars text-dark" onClick={btnn}></i>


                </nav>
            </header>
            {/* <!-- <Header End============= --> */}

        </div>
    )
}
export default Header;