import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import About from "../src/Aboutus/About";
import Contact from "../src/Contact/Contact";
import Products from '../src/Products/Products';
// import Cart from '../src/Cart/Cart';
import Home from './Home/Home';
// import CheckOut from './Checkout/CheckOut';
// import Login from './Login/Login';
import Footer from './Footer/Footer';
import Cushion_acces from './Accessories/Cushion_acces';
import Poufs from './Accessories/Poufs';
import Braid_rugs from './Accessories/Braid_rugs';
import ScrollToTop from './ScrollToTop';
import Handwoven_punja from './Accessories/Handwoven_punja';
import HandTuftedCarpet from './Accessories/HandTuftedCarpet';
import Handwoven_pitloom from './Accessories/Handwoven_pitloom';
import AboutUs from './Aboutus/AboutUs';
import Feedback from './feedback/Feedback';
import Terms from './extracomponents/Terms';
import Privacy from './extracomponents/Privacy';
import WhatsNew from './Accessories/whatsnew/WhatsNew';
import Infrastructure from './Accessories/infrastructure/Infrastructure';
import About from './Aboutus/About';
import CarpetCare from './Accessories/carpetcare/CarpetCare';
import Certificate from './Accessories/certificate/Certificate';
import Client from './Accessories/clients/Client';
import Exhibition from './Accessories/exhibition/Exhibition';
// import Factory from './Accessories/factory/Factory';
// import Handloom_carpets from './Accessories/Handloom_carpets';
const Handloom_carpets = React.lazy(() => import('./Accessories/Handloom_carpets'));
function App() {
  return (
    <>
      <Router>
      {/* <ScrollToTop/> */}

        <Switch>

          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/cushion_acces" component={Cushion_acces}  />
          <Route exact path="/poufs" component={Poufs} />
          <Route exact path="/braid_rugs" component={Braid_rugs} />
          <Route exact path="/handwoven-punja" component={Handwoven_punja} />
          <Route exact path="/handtufted-carpet" component={HandTuftedCarpet} />
          <Route exact path="/handwoven-pitloom" component={Handwoven_pitloom} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/feedback" component={Feedback} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/whats-new" component={WhatsNew} />
          <Route exact path="/infrastructure" component={Infrastructure} />
          <Route exact path="/carpet-care" component={CarpetCare} />
          <Route exact path="/certification" component={Certificate} />
          <Route exact path="/client" component={Client} />
          <Route exact path="/exhibition" component={Exhibition} />
          {/* <Route exact path="/factory" component={Factory} /> */}
          <Suspense fallback={<>Loading</>}>
            <Route exact path="/handloom_carpet" component={Handloom_carpets} />
          </Suspense>
        </Switch>
        
      </Router>
      
    </>



  );
}

export default App;
