export default [
    {
        Id: 1,
        imgsrc: require("../../images/exhibitions/(1).jpeg").default,
        spinner:"text-primary",
    },
    {
        Id: 2,
        imgsrc: require( "../../images/exhibitions/(2).jpeg").default,
        spinner:"text-secondary",
    },
    {
        Id: 3,
        imgsrc: require( "../../images/exhibitions/(3).jpeg").default,
        spinner:"text-success",
    },
    {
        Id: 4,
        imgsrc: require( "../../images/exhibitions/(4).jpeg").default,
        spinner:"text-danger",
    },
    {
        Id: 5,
        imgsrc: require( "../../images/exhibitions/(5).jpeg").default,
        spinner:"text-warning",
    },
    {
        Id: 6,
        imgsrc: require( "../../images/exhibitions/(6).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 7,
        imgsrc: require( "../../images/exhibitions/(7).jpeg").default,
        spinner:"text-light",
    },
    {
        Id: 8,
        imgsrc: require( "../../images/exhibitions/(8).jpeg").default,
        spinner:"text-dark",
    },
    {
        Id: 9,
        imgsrc: require( "../../images/exhibitions/(9).jpeg").default,
        spinner:"text-warning",
    },
    {
        Id: 10,
        imgsrc: require( "../../images/exhibitions/(10).JPG").default,
        spinner:"text-success",
    },
    
    {
        Id: 12,
        imgsrc: require( "../../images/exhibitions/(12).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 13,
        imgsrc: require( "../../images/exhibitions/(13).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 14,
        imgsrc: require( "../../images/exhibitions/(14).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 15,
        imgsrc: require( "../../images/exhibitions/(15).jpeg").default,
        spinner:"text-info",
    },
    
    {
        Id: 17,
        imgsrc: require( "../../images/exhibitions/(17).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 18,
        imgsrc: require( "../../images/exhibitions/(18).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 19,
        imgsrc: require( "../../images/exhibitions/(19).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 20,
        imgsrc: require( "../../images/exhibitions/(20).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 21,
        imgsrc: require( "../../images/exhibitions/(21).jpeg").default,
        spinner:"text-info",
    },
   
    {
        Id: 23,
        imgsrc: require( "../../images/exhibitions/(23).jpeg").default,
        spinner:"text-info",
    },
    
    {
        Id: 25,
        imgsrc: require( "../../images/exhibitions/(25).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 26,
        imgsrc: require( "../../images/exhibitions/(26).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 27,
        imgsrc: require( "../../images/exhibitions/(27).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 28,
        imgsrc: require( "../../images/exhibitions/(28).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 29,
        imgsrc: require( "../../images/exhibitions/(29).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 30,
        imgsrc: require( "../../images/exhibitions/(30).JPG").default,
        spinner:"text-info",
    },
    {
        Id: 32,
        imgsrc: require( "../../images/exhibitions/(32)-min.jpg").default,
        spinner:"text-info",
    },
    {
        Id: 33,
        imgsrc: require( "../../images/exhibitions/(33)-min.jpg").default,
        spinner:"text-info",
    },
    {
        Id: 34,
        imgsrc: require( "../../images/exhibitions/(34)-min.jpg").default,
        spinner:"text-info",
    },
    {
        Id: 35,
        imgsrc: require( "../../images/exhibitions/(35)-min.jpg").default,
        spinner:"text-info",
    },
    {
        Id: 36,
        imgsrc: require("../../images/exhibitions/(36)-min.JPG").default,
        spinner:"text-info",
    },
    {
        Id: 37,
        imgsrc: require("../../images/exhibitions/(37)-min.JPG").default,
        spinner:"text-info",
    },
    {
        Id: 38,
        imgsrc: require("../../images/exhibitions/(38)-min.JPG").default,
        spinner:"text-info",
    },
    {
        Id: 39,
        imgsrc: require("../../images/exhibitions/(39)-min.jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 11,
        imgsrc: require( "../../images/exhibitions/(11).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 16,
        imgsrc: require( "../../images/exhibitions/(16).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 22,
        imgsrc: require( "../../images/exhibitions/(22).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 24,
        imgsrc: require( "../../images/exhibitions/(24).jpeg").default,
        spinner:"text-info",
    },

   

];
