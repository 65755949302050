export default [
    {
        Id: 1,
        imgsrc: require("../../images/newproduct/(1).jpeg").default,
        spinner:"text-primary",
        code:'VDTF-694',
    },
    {
        Id: 2,
        imgsrc: require("../../images/newproduct/(2).jpeg").default,
        spinner:"text-secondary",
        code:'VDTF-697',
    },
    {
        Id: 3,
        imgsrc: require("../../images/newproduct/(3).jpeg").default,
        spinner:"text-success",
        code:'VDTF-692',
    },
    {
        Id: 4,
        imgsrc: require("../../images/newproduct/(4).jpeg").default,
        spinner:"text-danger",
        code:'VDTF-698',
    },
    {
        Id: 5,
        imgsrc: require("../../images/newproduct/(5).jpeg").default,
        spinner:"text-warning",
        code:'VDTF-696',
    },
    {
        Id: 6,
        imgsrc: require("../../images/newproduct/(6).jpeg").default,
        spinner:"text-info",
        code:'VDTF-695',
    },
    {
        Id: 7,
        imgsrc: require("../../images/newproduct/(7).jpeg").default,
        spinner:"text-light",
        code:'VDTF-693',
    },
    {
        Id: 8,
        imgsrc: require("../../images/newproduct/(8).jpeg").default,
        spinner:"text-dark",
        code:'VDTF-699',
    },
    {
        Id: 9,
        imgsrc: require("../../images/newproduct/(9).jpeg").default,
        spinner:"text-warning",
        code:'VDTF-691',
    },
    {
        Id: 10,
        imgsrc: require("../../images/newproduct/(10).jpeg").default,
        spinner:"text-success",
        code:'VDST-211',
    },
    {
        Id: 11,
        imgsrc: require("../../images/newproduct/(11).jpeg").default,
        spinner:"text-info",
        code:'VDTF-02',
    },
    {
        Id: 12,
        imgsrc: require("../../images/newproduct/(12).jpeg").default,
        spinner:"text-info",
        code:'VDPJ-607(5)',
    },
    {
        Id: 13,
        imgsrc: require("../../images/newproduct/(13).jpeg").default,
        spinner:"text-info",
        code:'VDPJ-10022',
    },
    {
        Id: 14,
        imgsrc: require("../../images/newproduct/(14).jpeg").default,
        spinner:"text-info",
        code:'VDTF-419',
    },
    {
        Id: 15,
        imgsrc: require("../../images/newproduct/(15).jpeg").default,
        spinner:"text-info",
        code:'VDPT-6229',
    },
    {
        Id: 16,
        imgsrc: require("../../images/newproduct/(16).jpeg").default,
        spinner:"text-info",
        code:'VDTF-501',
    },
    {
        Id: 17,
        imgsrc: require("../../images/newproduct/(17).jpeg").default,
        spinner:"text-info",
        code:' VDPT-2061',
    },
    {
        Id: 18,
        imgsrc: require("../../images/newproduct/(18).jpeg").default,
        spinner:"text-info",
        code:'VDTF-559',
    },
    {
        Id: 19,
        imgsrc: require("../../images/newproduct/(19).jpeg").default,
        spinner:"text-info",
        code:' VDTF-512',
    },
    {
        Id: 20,
        imgsrc: require("../../images/newproduct/(20).jpeg").default,
        spinner:"text-info",
        code:' VDTF-556',
    },
    {
        Id: 21,
        imgsrc: require("../../images/newproduct/(21).jpeg").default,
        spinner:"text-info",
        code:'VDTF-348',
    },
    {
        Id: 22,
        imgsrc: require("../../images/newproduct/(22).jpeg").default,
        spinner:"text-info",
        code:' VDTF-560',
    },
    {
        Id: 23,
        imgsrc: require("../../images/newproduct/(23).jpeg").default,
        spinner:"text-info",
        code:'VDPJ-302(2)',
    },
    {
        Id: 24,
        imgsrc: require("../../images/newproduct/(24).jpeg").default,
        spinner:"text-info",
        code:'VDPT-7142(1)',
    },
    {
        Id: 25,
        imgsrc: require("../../images/newproduct/(25).jpeg").default,
        spinner:"text-info",
        code:'VDPJ-748',
    },
    {
        Id: 26,
        imgsrc: require("../../images/newproduct/(26).jpeg").default,
        spinner:"text-info",
        code:'VDPJ-686(2)',
    },
    {
        Id: 27,
        imgsrc: require("../../images/newproduct/(27).jpeg").default,
        spinner:"text-info",
        code:'VDTF-591',
    },
    {
        Id: 28,
        imgsrc: require("../../images/newproduct/(28).jpeg").default,
        spinner:"text-info",
        code:'VDTF-619',
    },
    {
        Id: 29,
        imgsrc: require("../../images/newproduct/(29).jpeg").default,
        spinner:"text-info",
        code:'9 VDTF-611',
    },
    {
        Id: 30,
        imgsrc: require("../../images/newproduct/(30).jpeg").default,
        spinner:"text-info",
        code:'VDTF-515',
    },
    // {
    //     Id: 31,
    //     imgsrc: require("../../images/newproduct/(31).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 32,
    //     imgsrc: require("../../images/newproduct/(32).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 33,
    //     imgsrc: require("../../images/newproduct/(33).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 34,
    //     imgsrc: require("../../images/newproduct/(34).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 35,
    //     imgsrc: require("../../images/newproduct/(35).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 36,
    //     imgsrc: require("../../images/newproduct/(36).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 37,
    //     imgsrc: require("../../images/newproduct/(37).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 38,
    //     imgsrc: require("../../images/newproduct/(38).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 39,
    //     imgsrc: require("../../images/newproduct/(39).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 40,
    //     imgsrc: require("../../images/newproduct/(40).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 41,
    //     imgsrc: require("../../images/newproduct/(41).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 42,
    //     imgsrc: require("../../images/newproduct/(42).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 43,
    //     imgsrc: require("../../images/newproduct/(43).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 44,
    //     imgsrc: require("../../images/newproduct/(44).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 45,
    //     imgsrc: require("../../images/newproduct/(45).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 46,
    //     imgsrc: require("../../images/newproduct/(46).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 47,
    //     imgsrc: require("../../images/newproduct/(47).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 48,
    //     imgsrc: require("../../images/newproduct/(48).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 49,
    //     imgsrc: require("../../images/newproduct/(49).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 50,
    //     imgsrc: require("../../images/newproduct/(50).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 51,
    //     imgsrc: require("../../images/newproduct/(51).JPEG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 52,
    //     imgsrc: require("../../images/newproduct/(52).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 53,
    //     imgsrc: require("../../images/newproduct/(53).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 54,
    //     imgsrc: require("../../images/newproduct/(54).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 55,
    //     imgsrc: require("../../images/newproduct/(55).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 56,
    //     imgsrc: require("../../images/newproduct/(56).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 57,
    //     imgsrc: require("../../images/newproduct/(57).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 58,
    //     imgsrc: require("../../images/newproduct/(58).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 59,
    //     imgsrc: require("../../images/newproduct/(59).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 60,
    //     imgsrc: require("../../images/newproduct/(60).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 61,
    //     imgsrc: require("../../images/newproduct/(61).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 62,
    //     imgsrc: require("../../images/newproduct/(62).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 63,
    //     imgsrc: require("../../images/newproduct/(63).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 64,
    //     imgsrc: require("../../images/newproduct/(64).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 65,
    //     imgsrc: require("../../images/newproduct/(65).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 66,
    //     imgsrc: require("../../images/newproduct/(66).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 67,
    //     imgsrc: require("../../images/newproduct/(67).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 68,
    //     imgsrc: require("../../images/newproduct/(68).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 69,
    //     imgsrc: require("../../images/newproduct/(69).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 70,
    //     imgsrc: require("../../images/newproduct/(70).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 71,
    //     imgsrc: require("../../images/newproduct/(71).jpeg").default,
    //     spinner:"text-info",
    // },

   

];
