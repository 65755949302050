import React from 'react';
import { Link } from 'react-router-dom';

export const HeaderDropDown = () => {
    return <>
        <li class="mydropdown d-none d-lg-block">
            <a class="service1" id='about_open'>Products</a>
            <div class="service-menu" id='service-menu'>

                <div class="service-submenu" style={{ width: "34%" }}>
                    <h6>VINI DECOR</h6>
                    <p><i>VINI DECOR, a Govt. Recognized Export House, is a Subsidiary of M/s Vishal Woollen Mills, a Carpet Yarn manufacturing Unit Since Last 40 Yearand Manufacturing Woollen carpets since 2OOO Exclusively for domestic markets.</i></p>
                    <div>
                        <Link to="/aboutus" className="btn1 btn-4"><span>Read More</span></Link>
                    </div>
                </div>
                <div class="service-submenu" style={{ width: "22%" }}>
                    <h6>RUGS & CARPETS</h6>
                    <Link to="/braid_rugs">Braid Rugs </Link>
                    <Link to="/handloom_carpet">Handloom Texture Carpets</Link>
                    {/* <Link to="">Handmade Kilims</Link> */}
                    <Link to="/handtufted-carpet">Handmade Tufted Carpets</Link>
                    <Link to="/handwoven-pitloom">Handwoven Pitloom Rugs</Link>
                    <Link to="/handwoven-punja">Handwoven Punja Weaves</Link>
                    {/* <Link to="">Jaquard carpets</Link>
                                    <Link to="">Lumber Wool Rugs</Link>
                                    <Link to="">Moustache Flokati Rugs</Link>
                                    <Link to="">Natural Texture Rugs</Link> */}
                </div>
                {/* <div class="service-submenu" style={{ width: "22%" }}>
                    <h6>FLOORING</h6>
                    <Link to="/">Leather Flooring</Link>
                </div> */}
                <div class="service-submenu" style={{ width: "22%" }}>
                    <h6>ACCESSORIES</h6>
                    {/* <Link to="/">Leather Flooring</Link> */}
                    <Link to="/cushion_acces">Cushions</Link>
                    <Link to="/poufs">Poufs</Link>
                    {/* <Link to="/">Throws</Link> */}
                </div>
                <div class="service-submenu" style={{ width: "22%" }}>
                    <h6>OTHER INFO</h6>
                    <Link to="/whats-new">What's New</Link>
                    <Link to="/infrastructure">Infrastructure</Link>
                    <Link to="/carpet-care">Carpet Care</Link>
                    <Link to="/client">Client</Link>
                    <Link to="/exhibition">Exhibition</Link>
                </div>
            </div>
        </li>
    </>;
};
