import React from 'react';
import { Link } from 'react-router-dom';

const ProductRange = () => {
  return <>
  <div className="text-center mb-5">
                <h2>OUR PRODUCTS RANGE</h2>
                {/* <b>6 different shapes with distinctive designs to make any ambience exquisite and refined.</b> */}
            </div>
            <div className="scrollmenu container-fluid mb-5" style={{ paddingBottom: '60px' }}>
                <Link to="/braid_rugs">
                    <div className="col-lg-12">
                        <div className="scroll_section" style={{}}>
                            <div className="ecom_section1">
                                {/* <img  alt="" /> */}
                                <img src={require('../images/scrollimage/rug.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4 px-4" style={{}}>
                                    <h2 className="m-0">Braid Rugs</h2>
                                    {/* <p className='d-flex text-wrap'>Our braided rugs are made from a modernized version of an old yet versatile technique used to display its appeal and charm.</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link to="/handloom_carpet">
                    <div className="col-lg-12">
                        <div className="scroll_section">
                            <div className="ecom_section1" style={{}}>
                                <img src={require('../images/scrollimage/handloomcarpet.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0">Handloom Texture Carpets</h2>
                                    {/* <p className='d-flex text-wrap'>Our range of handloom texture carpets are famed for high tensile strength and subsequent longevity with a comforting style.  </p> */}
                                </div>
                            </div>


                        </div>
                    </div>
                </Link>
                <Link to="/handtufted-carpet">
                    <div className="col-lg-12">
                        <div className="scroll_section">
                            <div className="ecom_section1" style={{}}>
                                <img src={require('../images/scrollimage/3.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0">Handmade Tufted Carpets</h2>
                                    {/* <p className='d-flex text-wrap'>Our tufted carpets are made of high-quality pure wool that makes the carpet durable, and its true colors last for years.  </p> */}
                                </div>
                            </div>


                        </div>
                    </div>
                </Link>
                <Link to="/handwoven-pitloom">
                    <div className="col-lg-12">
                        <div className="scroll_section">
                            <div className="ecom_section1" style={{}}>
                                <img src={require('../images/scrollimage/handwovenpitloom.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0">Handwoven Pitloom Rugs</h2>
                                    {/* <p className='d-flex text-wrap'>Our handwoven pitloom rugs are a perfect mix of sophistication and chic designed for extended comfort. </p> */}
                                </div>
                            </div>


                        </div>
                    </div></Link>
                <Link to="/handwoven-punja">
                    <div className="col-lg-12">
                        <div className="scroll_section">
                            <div className="ecom_section1" style={{}}>
                                <img src={require('../images/scrollimage/handwovenpunja.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0">Handwoven Punja Weaves</h2>
                                    {/* <p className='d-flex text-wrap'>Our handwoven Punja weaves come in designer patterns customized to last long and are easy to use and wash.  </p> */}
                                </div>
                            </div>


                        </div>
                    </div>
                </Link>
                <Link to="/cushion_acces">
                    <div className="col-lg-12">
                        <div className="scroll_section">
                            <div className="ecom_section1" style={{}}>
                                <img src={require('../images/scrollimage/cushion.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0">Cushions</h2>
                                    {/* <p className='d-flex text-wrap'>Our decorative range of cushions is designed to give your home a final bit of cherry on the top with its vibrant colors and designs.  </p> */}
                                </div>
                            </div>


                        </div>
                    </div>
                </Link>
                {/* <a href="#tools">
                    <div className="col-lg-12">
                        <div className="scroll_section">
                            <div className="ecom_section1" style={{}}>
                                <img src={require('../Home/images/scrollcard6.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0">Poufs</h2>
                                    <p>this is collection of itemshhhhjjkn djkjjdw  </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </a> */}
                <Link to="/poufs">
                    <div className="col-lg-12">
                        <div className="scroll_section">
                            <div className="ecom_section1" style={{}}>
                                <img src={require('../images/scrollimage/poufs.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0">Poufs</h2>
                                    {/* <p className='d-flex text-wrap'>Our wide range of poufs is designed to provide vibrant and comfortable seating in a small space that can be put away when not in use.  </p> */}
                                </div>
                            </div>


                        </div>
                    </div>
                </Link>
                {/* <a href="#custom">
                    <div className="col-lg-12">
                        <div className="scroll_section">
                            <div className="ecom_section1" style={{}}>
                                <img src={require('../Home/images/scrollcard2.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0">Cushions</h2>
                                    <p>this is collection of itemshhhhjjkn djkjjdw  </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </a> */}

            </div>
            
  
  </>;
};

export default ProductRange;
