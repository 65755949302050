import React, { useEffect, useState } from 'react'
import '../../Products/Product.css';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import clientdata from './clientdata';
import FooterContact from '../../extracomponents/FooterContact';
import ProductRange from '../../extracomponents/ProductRange';
import ProductHeader from '../../extracomponents/ProductHeader';
import Productcategory from '../../extracomponents/Productcategory';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import imgbg from "../../images/client/(1).jpeg"

const Client = () => {

    const bgstyle = {

        height: "60vh",
        // margin: "50px",
        // backgroundPosition: "center",
        // backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        background: `linear-gradient(180deg, rgba(0, 0,0, 0.7), rgba(2, 0, 0, .7)), url("${imgbg}")`
        // backgroundImage: `url(${imgbg})`
        // backgroundImage: `url(${imgbg})`


    }
    const [spinner, setspinner] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
            setspinner(false)
        }, 500);

    }, []);

    const navbraanimate = () => {
        // window.addEventListener("scroll",function(){
        //     const header = document.querySelector('header');
        //     header.classList.toggle('sticky',window.scrollY > 0);
        // });
        const navigation = document.querySelector('nav');

        document.querySelector('.toggle').onclick = function () {
            this.classList.toggle('active');
            navigation.classList.toggle('active');
        }
    }
    return <>
        {
            !spinner && <div>
                <Header />
                <div className='d-flex justify-content-center' style={bgstyle}>
                    <div className="row gx-0  p-2 align-items-center  text-white">
                        <div className="col-12 text-center">
                            <h1>Clients</h1>
                            <h5>Words don’t convey as much as pictures do! Meet our happy clients with the collection of our rugs yourself!</h5>
                        </div>

                    </div>

                </div>
                {/* <img src={imgbg} alt="" /> */}

                {/* Gallery==== */}
                {/* <section className="text-center " style={{ marginTop: '30vh' }}>

                    <h2>Clients</h2>
                    <div className="row d-flex justify-content-center">
                        <div className="col-8 text-center">

                            <h5>Words don’t convey as much as pictures do! Meet our happy clients with the collection of our rugs yourself!</h5>
                        </div>
                    </div>
                </section> */}
                <div class="container py-5">
                    <div class="gallery">
                        {
                            clientdata.map((item) => (
                                <>
                                    <a href={item.imgsrc}
                                        data-lightbox="models" data-title="Caption1">
                                        <LazyLoadImage
                                            // alt={image.alt}
                                            effect="blur"
                                            src={item.imgsrc}

                                        />

                                    </a>

                                </>
                            ))
                        }




                    </div>
                </div>
                {/* Gallery==== */}

                {/* Cards Animated=== */}

                {/* {Scroll Bar Cards}    */}
                {/* <ProductRange/> */}
                {/* <Productcategory /> */}
                {/* {Scroll Bar Cards}    */}

                {/* Cards Animated=== */}


                            <div className="d-flex justify-content-center py-5">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/yIQYcW92ang" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>



                {/* Contact=== */}
                <FooterContact />
                {/* Contact=== */}
                <Footer />



            </div>
        }
    </>
};

export default Client;
