import React,{useEffect,useState} from 'react'
import '../Products/Product.css';
import { Link } from 'react-router-dom';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import handtufted_carpet from './handtufted_carpet';
import 'react-lazy-load-image-component/src/effects/blur.css';
import FooterContact from '../extracomponents/FooterContact';
// import ProductRange from '../extracomponents/ProductRange';
import ProductHeader from '../extracomponents/ProductHeader';
import Productcategory from '../extracomponents/Productcategory';
import Footer from '../Footer/Footer';

const HandTuftedCarpet = () => {
    const [spinner, setspinner] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
            setspinner(false)
        }, 500);

    }, []);

    const navbraanimate = () => {
        // window.addEventListener("scroll",function(){
        //     const header = document.querySelector('header');
        //     header.classList.toggle('sticky',window.scrollY > 0);
        // });
        const navigation = document.querySelector('nav');

        document.querySelector('.toggle').onclick = function () {
            this.classList.toggle('active');
            navigation.classList.toggle('active');
        }
    }
    // Navbar Script==


    return (
        <>
        {
            !spinner && <div>


            {/* Sticky Navbar With Animation Effect */}
            <header className="productheader" style={{ zIndex: '1' }}>
                <img src={require('../Products/images/ll.png').default} className="banner" alt="" />
                <a href="#" className="logo text-center">HANDTUFTED CARPETS</a>
                <div className="bg" style={{ backgroundColor: 'rgba(0,0,0,0.3)', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}>

                </div>
                <div class="toggle" onClick={navbraanimate}></div>
               <ProductHeader/>
            </header>
            {/* Sticky Navbar With Animation Effect */}


            {/* Gallery==== */}
            <section className="text-center " style={{ marginTop: '30vh' }}>
                <h2>Our Gallery</h2>
            </section>
            <div class="container py-5">
                <div class="gallery">
                    {
                        handtufted_carpet.map((item) => (
                            <>
                                <a className='nav-link' href={item.imgsrc}
                                    data-lightbox="models" data-title={item.code}>
                                    <LazyLoadImage
                                        // alt={image.alt}
                                        effect="blur"
                                        src={item.imgsrc}
                                       
                                        />

                                            <div className="text-center d-flex align-items-center justify-content-center">
                                                <span className='px-5  text-white' style={{ backgroundColor: '#636e72' }}>{item.code}</span>
                                            </div>
                                </a>

                            </>
                        ))
                    }
                   
                   
                   

                </div>
            </div>
            {/* Gallery==== */}

            {/* Cards Animated=== */}

            {/* {Scroll Bar Cards}    */}
            <Productcategory/>
            {/* {Scroll Bar Cards}    */}

            {/* Cards Animated=== */}
            {/* Contact=== */}
            <FooterContact/>
            {/* Contact=== */}
            <Footer/>




        </div>
        }
        
        </>
        
    )
}
export default HandTuftedCarpet



