import React,{useEffect,useState} from 'react'
import '../Products/Product.css';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PoufsData from './PoufsData';
import FooterContact from '../extracomponents/FooterContact';
import ProductRange from '../extracomponents/ProductRange';
import ProductHeader from '../extracomponents/ProductHeader';
import Productcategory from '../extracomponents/Productcategory';
import Footer from '../Footer/Footer';

 const Poufs = () => {


    const [spinner, setspinner] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
            setspinner(false)
        }, 500);

    }, []);








       // Navbar Script==
    const navbraanimate = () => {
        // window.addEventListener("scroll",function(){
        //     const header = document.querySelector('header');
        //     header.classList.toggle('sticky',window.scrollY > 0);
        // });
        const navigation = document.querySelector('nav');

        document.querySelector('.toggle').onclick = function () {
            this.classList.toggle('active');
            navigation.classList.toggle('active');
        }
    }
    // Navbar Script==

    // Cars Animation Script==
    function moveF() {
        document.querySelector(".card-container").style.width = "98vw";
        document.querySelector("#card1").style.left = "20vw";
        document.querySelector("#card2").style.left = "40vw";
        document.querySelector("#card3").style.left = "60vw";
        document.querySelector("#card4").style.left = "80vw";
        var nodes = document
            .querySelector(".card-container")
            .getElementsByTagName("div");
        for (var i = 0; i < nodes.length; i++) {
            nodes[i].style.transform = "translate(-50%, -50%)";
        }
        document.querySelector("#btn").style.display = "block";
    }
    function moveF2() {
        document.querySelector(".card-container").style.width = "100vw";
        document.querySelector("#card1").style.left = "20vw";
        document.querySelector("#card1").style.top = "10vh";
        document.querySelector("#card2").style.left = "80vw";
        document.querySelector("#card2").style.top = "10vh";
        document.querySelector("#card3").style.left = "20vw";
        document.querySelector("#card3").style.top = "50vh";
        document.querySelector("#card4").style.left = "80vw";
        document.querySelector("#card4").style.top = "50vh";
        var nodes = document
            .querySelector(".card-container")
            .getElementsByTagName("div");
        for (var i = 0; i < nodes.length; i++) {
            nodes[i].style.transform = "translate(-50%, -50%)";
        }
        document.querySelector("#btn").style.display = "block";
    }
    function moveB() {
        document.querySelector("#card1").style.left = "50%";
        document.querySelector("#card1").style.top = "50%";
        document.querySelector("#card2").style.left = "50%";
        document.querySelector("#card2").style.top = "50%";
        document.querySelector("#card3").style.left = "50%";
        document.querySelector("#card3").style.top = "50%";
        document.querySelector("#card4").style.left = "50%";
        document.querySelector("#card4").style.top = "50%";
        document.querySelector("#card1").style.transform =
            "translate(-50%,-50%) rotate(10deg)";
        document.querySelector("#card2").style.transform =
            "translate(-50%,-50%) rotate(5deg)";
        document.querySelector("#card3").style.transform =
            "translate(-50%,-50%) rotate(-5deg)";
        document.querySelector("#card4").style.transform =
            "translate(-50%,-50%) rotate(-10deg)";
        document.querySelector("#btn").style.display = "none";
    }
    // const mediaQuery = window.matchMedia("(max-width: 650px)");
    // if (mediaQuery.matches) {
    //     var element = document.querySelector(".card-container");
    //     element.removeAttribute("onclick");
    //     element.setAttribute("onclick", "moveF2()");
    // }
    // Cars Animation Script==
    return (
        <>
        {
            !spinner &&<div>
            

            {/* Sticky Navbar With Animation Effect */}
             <header className="productheader" style={{ zIndex: '1' }}>
                <img src={require('../Products/images/ll.png').default} className="banner" alt="" />
                <a href="#" className="logo">POUFS</a>
                <div className="bg" style={{ backgroundColor: 'rgba(0,0,0,0.3)', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}>

                </div>
                <div class="toggle" onClick={navbraanimate}></div>
                <ProductHeader/>
             </header>
            {/* Sticky Navbar With Animation Effect */}


            {/* Gallery==== */}
            <section className="text-center " style={{marginTop:'30vh'}}>
                <h2>Our Gallery</h2>
            </section>
            <div class="container py-5">
                <div class="gallery">
                   
                {
                        PoufsData.map((item) => (
                            <>
                                <a className='nav-link' href={item.imgsrc}
                                    data-lightbox="models" data-title={item.code}>
                                    <LazyLoadImage
                                    key={item.Id}
                                        // alt={image.alt}
                                        effect="blur"
                                        src={item.imgsrc}
                                       
                                    />

                                            <div className="text-center d-flex align-items-center justify-content-center">
                                                <span className='px-5  text-white' style={{ backgroundColor: '#636e72' }}>{item.code}</span>
                                            </div>
                                </a>

                            </>
                        ))
                    }
                </div>
            </div>
            {/* Gallery==== */}   






            {/* Cards Animated=== */}

               {/* {Scroll Bar Cards}    */}
        <div>
            {/* <ProductRange/> */}
            <Productcategory/>

            {/* <div className="scrollmenu2  container-fluid " style={{ paddingBottom: '60px' }}>

                <a href="#home">
                    <div className="col-lg-12">
                        <div className="scroll_section2" style={{ position: 'relative !important' }}>
                            <div className="ecom_section2">
                                <img src={require('../Products/images/scrollcard1.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>U</span>RBAN <span>| Collection</span></h2>
                                    <a href="#" class="btn btn-dark">View</a>

                                </div>
                            </div>



                        </div>
                    </div>
                </a>
                <a href="#news">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../Products/images/scrollcard2.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>U</span>RBAN <span>| Collection</span></h2>
                                </div>
                            </div>


                        </div>
                    </div>
                </a>
                <a href="#contact">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../Products/images/scrollcard3.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>U</span>RBAN <span>| Collection</span></h2>
                                </div>
                            </div>


                        </div>
                    </div>
                </a>
                <a href="#about">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../Products/images/scrollcard4.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>U</span>RBAN <span>| Collection</span></h2>
                                </div>
                            </div>


                        </div>
                    </div></a>
                <a href="#support">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../Products/images/scrollcard5.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>U</span>RBAN <span>| Collection</span></h2>
                                </div>
                            </div>


                        </div>
                    </div>
                </a>
                <a href="#blog">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../Products/images/scrollcard6.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>U</span>RBAN <span>| Collection</span></h2>
                                </div>
                            </div>


                        </div>
                    </div>
                </a>
                <a href="#tools">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../Products/images/scrollcard6.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                    <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>U</span>RBAN <span>| Collection</span></h2>
                                </div>
                            </div>


                        </div>
                    </div>
                </a>
                <a href="#base">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../Products/images/scrollcard1.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>U</span>RBAN <span>| Collection</span></h2>                                    </div>
                            </div>


                        </div>
                    </div>
                </a>
                <a href="#custom">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../Products/images/scrollcard2.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>U</span>RBAN <span>| Collection</span></h2>                                    </div>
                            </div>


                        </div>
                    </div>
                </a>

            </div> */}
        </div>
        {/* {Scroll Bar Cards}    */}

            {/* Cards Animated=== */}


         



            {/* Contact=== */}
            <FooterContact/>
            {/* Contact=== */}
            <Footer/>




        </div>
        }
        </>
        
    )
}
export default Poufs
