import React from 'react';
import { Link } from 'react-router-dom';

const Productcategory = () => {
    return <>


        <div className="text-center mb-5">
            <h2>Our Collections</h2>
            {/* <b>6 different shapes with distinctive designs to make any ambience exquisite and refined.</b> */}
        </div>
        <div className="scrollmenu2  container-fluid " style={{ paddingBottom: '60px' }}>

            <Link to="/braid_rugs">
                <div className="col-lg-12">
                    <div className="scroll_section2" style={{}}>
                        <div className="ecom_section2">
                            <img src={require('../images/scrollimage/rug.jpg').default} alt="" />
                            <div className=" scroll-text text-start px-4" style={{}}>
                                {/* <h2 >Braid Rugs</h2> */}
                                <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1.5rem' }}>B</span > <span style={{fontSize:"1rem"}}>raid Rugs</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
            <Link to="/handloom_carpet">
                <div className="col-lg-12">
                    <div className="scroll_section2">
                        <div className="ecom_section2" style={{}}>
                            <img src={require('../images/scrollimage/handloomcarpet.jpg').default} alt="" />
                            <div className=" scroll-text text-start px-4" style={{}}>
                                {/* <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1.5rem' }}>H</span>andloom Carpets <span>| Collection</span></h2> */}
                                <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1.5rem' }}>H</span > <span style={{fontSize:"1rem"}}>andloom Carpets</span></h2>
                                
                            </div>
                        </div>


                    </div>
                </div>
            </Link>

            <Link to="/handtufted-carpet">
                <div className="col-lg-12">
                    <div className="scroll_section2">
                        <div className="ecom_section2" style={{}}>
                            <img src={require('../images/scrollimage/3.jpg').default} alt="" />
                            <div className=" scroll-text text-start px-4" style={{}}>
                                {/* <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>H</span>andTufted Carpets<span>| Collection</span></h2> */}
                                <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1.5rem' }}>H</span > <span style={{fontSize:"1rem"}}>andTufted Carpets</span></h2>
                            </div>
                        </div>


                    </div>
                </div>
            </Link>
            <Link to="/handwoven-pitloom">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../images/scrollimage/handwovenpitloom.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                {/* <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>H</span>andWoven Pitloom <span>| Collection</span></h2> */}
                                <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1.5rem' }}>H</span > <span style={{fontSize:"1rem"}}>andWoven Pitloom</span></h2>
                            </div>
                            </div>


                        </div>
                    </div></Link>
                    <Link to="/handwoven-punja">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../images/scrollimage/handwovenpunja.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                {/* <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>H</span>andWoven Punja <span>| Collection</span></h2> */}
                                <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1.5rem' }}>H</span > <span style={{fontSize:"1rem"}}>andWoven Punja</span></h2>
                            </div>
                            </div>


                        </div>
                    </div>
                </Link>
                <Link to="/cushion_acces">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../images/scrollimage/cushion.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                {/* <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>C</span>ushion <span>| Collection</span></h2> */}
                                <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1.5rem' }}>C</span > <span style={{fontSize:"1rem"}}>ushion</span></h2>
                            </div>
                            </div>


                        </div>
                    </div>
                </Link>
                <Link to="/poufs">
                    <div className="col-lg-12">
                        <div className="scroll_section2">
                            <div className="ecom_section2" style={{}}>
                                <img src={require('../images/scrollimage/poufs.jpg').default} alt="" />
                                <div className=" scroll-text text-start px-4" style={{}}>
                                {/* <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1rem' }}>P</span>oufs <span>| Collection</span></h2> */}
                                <h2 className="m-0"><span style={{ color: '#85523A !important', fontSize: '1.5rem' }}>P</span > <span style={{fontSize:"1rem"}}>oufs</span></h2>
                            </div>
                            </div>


                        </div>
                    </div>
                </Link>
            
        </div>


    </>;
};

export default Productcategory;
