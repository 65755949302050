import React from 'react';
import { Link } from 'react-router-dom';

const FooterContact = () => {
  return <>
  <div className="contact   container-fluid py-5 " style={{ backgroundColor: '#85523A' }}>

<div className="row justify-content-center ">
    <div className="col-lg-2 text-center">
        <img className="imgimg" src={require('../Home/images/logo.png').default} width="70%" height="70%" alt="" />
    </div>
</div>
<div className="row justify-content-center py-3">
    <div className="contect_head text-center col-lg-6">

        <h2 className="text-white">EXPERIENCE THE CHARMS OF CLASSY AND AFFORDABLE HOME DECOR</h2>
        <p className="text-white">Start your journey into the world of vibrant yet stylish and high-quality home décor available for all kinds of use!</p>

    </div>
</div>
<div className="row justify-content-center pb-3">
    <div className="col-lg-5 text-center">
        {/* <div className="input-group input-group-sm mb-3 "> */}
            {/* <input type="text" className="form-control me-3 rounded" style={{ fontSize: '1.3rem', paddingLeft: '20px' }} placeholder="Enter Email" aria-label="Enter Email" aria-describedby="basic-addon2" /> */}
            {/* <span class="btn btn-light rounded px-3" style={{  color: '#85523A', fontSize: '1.2rem', fontWeight: 'bolder' }} id="basic-addon2">Subscribe</span> */}
            <Link to="/whats-new" className="btn1 btn-4 bg-dark rounded text-center "><span>Know More</span></Link>
        {/* </div> */}
    </div>
</div>
</div>
  
  </>;
};

export default FooterContact;
