import React from 'react';
import { Link } from 'react-router-dom';

const ProductHeader = () => {
    return <>
        <nav>
            <ul>
                <li><Link to="/">Home</Link></li>
                <li class="mydropdown d-none d-lg-block">
                    <a class="service1" id='about_open' >Products</a>
                    <div class="service-menu1" id='service-menu'>

                        <div class="service-submenu1" style={{ width: "34%" }}>
                            <h6>VINI DECOR</h6>
                            <p><i>VINI DECOR, a Govt. Recognized Export House, is a Subsidiary of M/s Vishal Woollen Mills, a Carpet Yarn manufacturing Unit Since Last 40 Yearand Manufacturing Woollen carpets since 2OOO Exclusively for domestic markets.</i></p>
                            <div>
                                <a href="#" className="btn1 btn-4"><span>Read More</span></a>
                            </div>
                        </div>
                        <div class="service-submenu1" style={{ width: "22%" }}>
                            <h6>RUGS & CARPETS</h6>
                            <Link to="/braid_rugs">Braid Rugs </Link>
                            <Link to="/handloom_carpet">Handloom Texture Carpets</Link>
                            {/* <Link to="">Handmade Kilims</Link> */}
                            <Link to="/handtufted-carpet">Handmade tufted Carpets</Link>
                            <Link to="/handwoven-pitloom">Handwoven Pitloom Rugs</Link>
                            <Link to="/handwoven-punja">Handwoven Punja Weaves</Link>
                            {/* <Link to="">Jaquard carpets</Link>
                                    <Link to="">Lumber Wool Rugs</Link>
                                    <Link to="">Moustache Flokati Rugs</Link>
                                    <Link to="">Natural Texture Rugs</Link> */}
                        </div>
                        {/* <div class="service-submenu1" style={{ width: "22%" }}>
                            <h6>FLOORING</h6>
                            <Link to="/farmers">Leather Flooring</Link>
                        </div> */}
                        <div class="service-submenu1" style={{ width: "22%" }}>
                            <h6>ACCESSORIES</h6>
                            {/* <Link to="/farmers">Leather Flooring</Link> */}
                            <Link to="/cushion_acces">Cushions</Link>
                            <Link to="/poufs">Poufs</Link>
                            {/* <Link to="/farmers">Throws</Link> */}
                        </div>
                        <div class="service-submenu1" style={{ width: "22%" }}>
                            <h6>OTHER INFO</h6>
                            <Link to="/whats-new">What's New</Link>
                            <Link to="/infrastructure">Infrastructure</Link>
                            <Link to="/carpet-care">Carpet Care</Link>
                            <Link to="/client">Client</Link>
                            <Link to="/exhibition">Exhibition</Link>
                        </div>
                    </div>
                </li>
                {/* ====================== Mobile Drop */}
                <li><a data-toggle="collapse" href="#Products" role="button" class="d-lg-none" aria-expanded="false"
                    aria-controls="collapseExample">Products &nbsp;&nbsp;<i class="fas fa-plus"></i></a></li>
                <div style={{ paddingLeft: '5px' }} class="collapse d-lg-none" id="Products">

                    <li><a style={{ color: '#fff', fontSize: '15px', fontWeight: '200', paddingLeft: '35px' }} data-toggle="collapse" href="#drop1" role="button" aria-expanded="false"
                        aria-controls="collapseExample">Rugs & carpet&nbsp;&nbsp; <i class="fas fa-chevron-down"></i></a></li>
                    <div style={{ paddingLeft: '15px' }} class="collapse" id="drop1">
                        <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/braid_rugs">Braid Rugs</Link>
                        <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/handloom_carpet">Handloom Texture Carpets</Link>
                        <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/handtufted-carpet">Handmade Tufted Carpets</Link>
                        <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/handwoven-pitloom">Handwoven Pitloom Rugs</Link>
                        <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/handwoven-punja">Handwoven Punja Weaves</Link>

                        {/* <a style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" href="~/Showproduct/Productpage?Id=3">Handmade Kilims</a>
                                <a style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" href="~/Showproduct/Productpage?Id=4">Handmade Tuftef carpets</a>
                                <a style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" href="~/Showproduct/Productpage?Id=5">Handwoven Pitloom Rugs</a>
                                <a style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" href="~/Showproduct/Productpage?Id=6">Handwoven Punja Weaves</a>
                                <a style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" href="~/Showproduct/Productpage?Id=7">Jaquard Carpets</a>
                                <a style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" href="~/Showproduct/Productpage?Id=7">Lumber Wool Rugs</a>
                                <a style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" href="~/Showproduct/Productpage?Id=7">Moustache FloKati Rugs</a>
                                <a style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" href="~/Showproduct/Productpage?Id=7">Natural Texture Rugs</a> */}
                    </div><br />


                    <li><a style={{ color: '#fff', fontSize: '15px', fontWeight: '200', paddingLeft: '35px' }} data-toggle="collapse" href="#drop3" role="button" aria-expanded="false"
                        aria-controls="collapseExample">Accessories &nbsp;&nbsp;<i class="fas fa-chevron-down"></i></a></li>
                    <div style={{ paddingLeft: '15px' }} class="collapse" id="drop3">
                        {/* <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" >Leather Flooring</Link> */}
                        <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/cushion_acces">Cushions</Link>
                        <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/poufs">Poufs</Link>
                        {/* <Link style={{ color: '#fff', fontSize: '13px', fontWeight: '100' }} class="dropdown-item" to="/throws">Throws</Link> */}
                    </div>


                </div>
                {/* ====================== Mobile Drop */}
                <li><Link to="/aboutus">About Us</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                {/* <li><Link to="/">Home</Link></li> */}
            </ul>
        </nav>

    </>;
};

export default ProductHeader;
