import React,{useState,useEffect} from 'react'
import '../Products/Product.css';
import { Link } from 'react-router-dom';
import cushionAccessdata from './cushionAccessdata';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// import ProductRange from '../extracomponents/ProductRange';
import FooterContact from '../extracomponents/FooterContact';
import ProductHeader from '../extracomponents/ProductHeader';
import Productcategory from '../extracomponents/Productcategory';
import Footer from '../Footer/Footer';

 const Cushion_acces = () => {


    const [spinner, setspinner] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
            setspinner(false)
        }, 500);

    }, []);

    // export default Products

    // Navbar Script==
    const navbraanimate = () => {
        // window.addEventListener("scroll",function(){
        //     const header = document.querySelector('header');
        //     header.classList.toggle('sticky',window.scrollY > 0);
        // });
        const navigation = document.querySelector('nav');

        document.querySelector('.toggle').onclick = function () {
            this.classList.toggle('active');
            navigation.classList.toggle('active');
        }
    }
    // Navbar Script==

    // Cars Animation Script==
    // function moveF() {
    //     document.querySelector(".card-container").style.width = "98vw";
    //     document.querySelector("#card1").style.left = "20vw";
    //     document.querySelector("#card2").style.left = "40vw";
    //     document.querySelector("#card3").style.left = "60vw";
    //     document.querySelector("#card4").style.left = "80vw";
    //     var nodes = document
    //         .querySelector(".card-container")
    //         .getElementsByTagName("div");
    //     for (var i = 0; i < nodes.length; i++) {
    //         nodes[i].style.transform = "translate(-50%, -50%)";
    //     }
    //     document.querySelector("#btn").style.display = "block";
    // }
    // function moveF2() {
    //     document.querySelector(".card-container").style.width = "100vw";
    //     document.querySelector("#card1").style.left = "20vw";
    //     document.querySelector("#card1").style.top = "10vh";
    //     document.querySelector("#card2").style.left = "80vw";
    //     document.querySelector("#card2").style.top = "10vh";
    //     document.querySelector("#card3").style.left = "20vw";
    //     document.querySelector("#card3").style.top = "50vh";
    //     document.querySelector("#card4").style.left = "80vw";
    //     document.querySelector("#card4").style.top = "50vh";
    //     var nodes = document
    //         .querySelector(".card-container")
    //         .getElementsByTagName("div");
    //     for (var i = 0; i < nodes.length; i++) {
    //         nodes[i].style.transform = "translate(-50%, -50%)";
    //     }
    //     document.querySelector("#btn").style.display = "block";
    // }
    // function moveB() {
    //     document.querySelector("#card1").style.left = "50%";
    //     document.querySelector("#card1").style.top = "50%";
    //     document.querySelector("#card2").style.left = "50%";
    //     document.querySelector("#card2").style.top = "50%";
    //     document.querySelector("#card3").style.left = "50%";
    //     document.querySelector("#card3").style.top = "50%";
    //     document.querySelector("#card4").style.left = "50%";
    //     document.querySelector("#card4").style.top = "50%";
    //     document.querySelector("#card1").style.transform =
    //         "translate(-50%,-50%) rotate(10deg)";
    //     document.querySelector("#card2").style.transform =
    //         "translate(-50%,-50%) rotate(5deg)";
    //     document.querySelector("#card3").style.transform =
    //         "translate(-50%,-50%) rotate(-5deg)";
    //     document.querySelector("#card4").style.transform =
    //         "translate(-50%,-50%) rotate(-10deg)";
    //     document.querySelector("#btn").style.display = "none";
    // }
    // const mediaQuery = window.matchMedia("(max-width: 650px)");
    // if (mediaQuery.matches) {
    //     var element = document.querySelector(".card-container");
    //     element.removeAttribute("onclick");
    //     element.setAttribute("onclick", "moveF2()");
    // }
    // Cars Animation Script==


    return (


        <>
        {
            !spinner && <div>

            {/* Sticky Navbar With Animation Effect */}
            <header className="productheader" style={{ zIndex: '1' }}>
                <img src={require('../Products/images/ll.png').default} className="banner" alt="" />
                <a href="#" className="logo">CUSHIONS</a>
                <div className="bg" style={{ backgroundColor: 'rgba(0,0,0,0.3)', position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}>

                </div>
                <div class="toggle" onClick={navbraanimate}></div>
                <ProductHeader/>
                {/* <nav>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li class="mydropdown d-lg-block d-none">
                                <Link class="service1" id='about_open' to="/product">Products</Link>
                                <div class="service-menu1" id='service-menu'>

                                    <div class="service-submenu1" style={{ width: "34%" }}>
                                        <h6>VINI DECOR</h6>
                                        <p><i>VINI DECOR, a Govt. Recognized Export House, is a Subsidiary of M/s Vishal Woollen Mills, a Carpet Yarn manufacturing Unit Since Last 40 Yearand Manufacturing Woollen carpets since 2OOO Exclusively for domestic markets.</i></p>
                                        <div>
                                            <a href="#" className="btn1 btn-4"><span>Read More</span></a>    
                                        </div>
                                    </div>
                                    <div class="service-submenu1" style={{ width: "22%" }}>
                                        <h6>RUGS & CARPETS</h6>
                                        <Link to="/braid_rugs">Braid Rugs </Link>
                                    <Link to="/handloom_carpet">Handloom Texture Carpets</Link>
                                    
                                    <Link to="/handtufted-carpet">Handmade Tuftefd Carpets</Link>
                                    <Link to="/handwoven-pitloom">Handwoven Pitloom Rugs</Link>
                                    <Link to="/handwoven-punja">Handwoven Punja Weaves</Link>
                                    
                                    </div>
                                    <div class="service-submenu1" style={{ width: "22%" }}>
                                    <h6>FLOORING</h6>
                                    
                                </div>
                                <div class="service-submenu1" style={{ width: "22%" }}>
                                    <h6>ACCESSORIES</h6>
                                    
                                    <Link to="/cushion_acces">Cushions</Link>
                                    <Link to="/poufs">Poufs</Link>
                                    


                                </div>
                                </div>
                        </li>
                        mobile dropdown
                      <li><a data-toggle="collapse" href="#Products" role="button" class="d-lg-none" aria-expanded="false"
                            aria-controls="collapseExample">Products &nbsp;&nbsp;<i class="fas fa-plus"></i></a></li>
                        <div style={{ paddingLeft: '5px' }} class="collapse d-lg-none" id="Products">

                            <li><a style={{color: '#fff', fontSize: '15px',fontWeight:'200',paddingLeft:'35px'}} data-toggle="collapse" href="#drop1" role="button" aria-expanded="false"
                                aria-controls="collapseExample">Rugs & carpet&nbsp;&nbsp; <i class="fas fa-chevron-down"></i></a></li>
                            <div style={{paddingLeft: '15px'}} class="collapse" id="drop1">
                            <Link style={{color: '#fff', fontSize: '13px',fontWeight:'100'}} class="dropdown-item" to="/braid_rugs">Braid Rugs</Link>
                                <Link style={{color: '#fff', fontSize: '13px',fontWeight:'100'}} class="dropdown-item" to="/handloom_carpet">Handloom Texture Carpets</Link>
                                <Link style={{color: '#fff', fontSize: '13px',fontWeight:'100'}} class="dropdown-item" to="/handtufted-carpet">Handmade Tufted Carpets</Link>
                                <Link style={{color: '#fff', fontSize: '13px',fontWeight:'100'}} class="dropdown-item" to="/handwoven-pitloom">Handwoven Pitloom Rugs</Link>
                                <Link style={{color: '#fff', fontSize: '13px',fontWeight:'100'}} class="dropdown-item" to="/handwoven-punja">Handwoven Punja Weaves</Link>
                                
                                
                            </div><br />
                            <li><a style={{color: '#fff', fontSize: '15px',fontWeight:'200',paddingLeft:'35px'}} data-toggle="collapse" href="#drop2" role="button" aria-expanded="false"
                                aria-controls="collapseExample">Flooring &nbsp;&nbsp;<i class="fas fa-chevron-down"></i></a></li>
                            <div style={{paddingLeft: '15px'}} class="collapse" id="drop2">
                                <a style={{color: '#fff', fontSize: '13px',fontWeight:'100'}} class="dropdown-item" href="~/Showproduct/Productpage?Id=1">Leather Flooring</a>
                            </div><br />
                            <li><a style={{color: '#fff', fontSize: '15px',fontWeight:'200',paddingLeft:'35px'}} data-toggle="collapse" href="#drop3" role="button" aria-expanded="false"
                                aria-controls="collapseExample">Accessories &nbsp;&nbsp;<i class="fas fa-chevron-down"></i></a></li>
                            <div style={{paddingLeft: '15px'}} class="collapse" id="drop3">
                               
                                <Link style={{color: '#fff', fontSize: '13px',fontWeight:'100'}} class="dropdown-item" to="/cushion_acces">Cushions</Link>
                                <Link style={{color: '#fff', fontSize: '13px',fontWeight:'100'}} class="dropdown-item"  to="/poufs">Poufs</Link>
                               
                            </div>

                        </div>
                        //mobile dropdown end
                        <li><Link to="/aboutus">About Us</Link></li>
                        <li><Link to="/contact">Contact Us</Link></li>
                   
                    </ul>
                </nav> */}
            </header>
            {/* Sticky Navbar With Animation Effect */}

            


                {/* Gallery==== */}
            <section className="text-center " style={{marginTop:'30vh'}}>
                <h2>Our Gallery</h2>
            </section>
            <div class="container py-5">
                <div class="gallery">
                {
                        cushionAccessdata.map((item) => (
                            <>
                                <a href={item.imgsrc}
                                    data-lightbox="models" data-title={item.code}>
                                    <LazyLoadImage
                                    key={item.Id}
                                        // alt={image.alt}
                                        effect="blur"
                                        src={item.imgsrc}
                                    />

                                            <div className="text-center d-flex align-items-center justify-content-center">
                                                <span className='px-5  text-white' style={{ backgroundColor: '#636e72' }}>{item.code}</span>
                                            </div>
                                </a>

                            </>
                        ))
                    }
                    
                </div>
            </div>
            {/* Gallery==== */}   






            {/* Cards Animated=== */}

               {/* {Scroll Bar Cards}    */}
        <div>
            <Productcategory/>
        </div>
        {/* {Scroll Bar Cards}    */}

            {/* Cards Animated=== */}


         



            {/* Contact=== */}
            <FooterContact/>
            {/* Contact=== */}


        
            
           <Footer/>

        </div>
        }
        
        </>
        
    )
}
export default Cushion_acces
