
import React , {useEffect} from 'react';
import "./aboutus.css";
import Header from '../Header/Header';
import FooterContact from '../extracomponents/FooterContact';
import Footer from '../Footer/Footer';


const AboutUs = () => {

    useEffect(() => {
      window.scrollTo(0,0)
    
    }, [])
    
    let btn = document.querySelector('.menu_btn');
    let navbar = document.querySelector('.nav_icon');

    const btnn = () => {
        document.querySelector('.menu_btn').onclick = function () {
            alert('fffffff')
            navbar.classList.toggle('mobile');
            btn.classList.toggle('fa-times');
        }
        // btn.onclick = () =>{

        //               alert('fffffff')
        //         navbar.classList.toggle('mobile');
        //         btn.classList.toggle('fa-times');


        // }
    }
    return (
        <div>


            <Header />
            {/* <!-- <Header End============= --> */}




            {/* Who We Are */}
            <section className="about mb-5 d-flex align-items-center">
                <div className="row px-0 px-lg-5 align-items-center mx-auto my-5 py-4">
                    <div className="d-flex justify-content-center ">
                        <div className="col-lg-9 col-12 text-white background-overlay p-lg-5 p-0">
                            <h5 style={{ color: '#fff' }}>WHO WE ARE</h5>
                            <h1 class="display-5 ">Over 20 years of experience across industries.</h1>
                            <p className="text-white">
                                Vini Décor has been committed to providing top-quality and sustainable rugs throughout the country for the past 20 years. Supported by our customers, partners and hardworking employees, we have managed to establish our own In-House yarn and, Carpets & rugs manufacturing facilities in the city of Panipat which also serves as our center for production and export.
                            </p><br />
                            <em>
                                Through our well-established communication channels, we obtained significant connections spanning out all over the world allowing us to declare ourselves as financially secure and subsequently, provide the best of the best services to our customers at competitive prices.
                            </em>
                        </div>
                    </div>
                    {/* <div className="col-lg-5 col-12 aboutright  ">
                        <img src={require('../Aboutus/images/aboutushero.png').default} style={{width:'100%'}} alt="" />
                    </div> */}
                </div>
            </section>
            {/* Who We Are */}


            {/* We BElieve in */}
            <section className="believe my-5 bg-light">
                {/* <div className="believe_text text-center ">
                    <h5 class="mb-5">VINIDECOR BELIEVE</h5>
                    <h1>To reintroduce traditional and natural solutions to modern problems.</h1>
                    <p>We believe that finding the root cause of every problem is a way to find the solution.</p>
                </div> */}
                <div className="row container second-col mx-auto  mb-5 mt-lg-5">
                    <div className="col-lg-6 gx-5 mb-4">
                        <div class="card hovercard" >
                            <div class="card-body text-center">
                                <img src={require('../Aboutus/images/cloud.png').default} class="py-3" alt="" />
                                <h4 class="card-subtitle mb-2  " style={{color:"red"}}>OUR BELIEFS</h4>
                                {/* <h4 class="believe_text ">OUR BELIEFS</h4> */}
                                <p class="card-text">We believe that the reason for our success lies in the 3 P’s namely- People, product and presentation. We believe that providing the best products to our people with utmost presentation is the only way to success.<br />
                                    We know that it takes months to secure a customer but only seconds to lose them and hence, we only promise practically achievable quantity and quality which can be supplied by the promised date. <br />
                                    Our strength lies in the fact that we acknowledge that innovating and developing is the only way forward along with hard work and transparency to the customers and tunnels the vision to success.<br />
                                </p>
                                {/* <a href="#" class="card-link">Another link</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-4  mb-4 gx-5">
                        <div class="card hovercard" >
                            <div class="card-body text-center">
                                <img src={require('../Aboutus/images/product-development.png').default} class="py-3" alt="" />
                                <h4 class="card-subtitle mb-2  " style={{color:"red"}}>MISSION AND VISION</h4>
                                <p class="card-text">Our mission is three-fold. We wish to be straightforward to combat the anticipated increase in competition by providing the highest quality products and services at the lowest possible prices. We aim to achieve the highest level of customer satisfaction while maintaining and growing our profits. Our employees are highly valuable to us. We want to treat them as our most valuable asset and, give them the best future opportunities in exchange for their efficient and productive efforts.<br/><br/>
                                Our objective is to enhance our market share by attracting new clients and retaining existing ones. 



                                </p>
                                {/* <a href="#" class="card-link">Card link</a> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4  mb-4 gx-5">
                        <div class="card hovercard" style={{ width: '18rem' }}>
                            <div class="card-body text-center">
                                <img src={require('../Aboutus/images/cloud.png').default} class="py-3" alt="" />
                                <h4 class="card-subtitle mb-2 text-muted ">Authentic products</h4>
                                <p class="card-text text-center">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            {/* We BElieve in */}



            <div class="container-fluid" style={{ padding: '0' }}>

                <div class="row align-items-center gx-0">
                    <div class="col-lg-6 col-12 left_sec" style={{ height: ' 100vh' }}>

                    </div>
                    <div class="col-lg-6 py-5 px-5 col-12">
                        <h5 style={{ color: '#EB2025', fontSize: '20px' }}>A MESSAGE FROM OUR FOUNDER </h5>
                        <p>
                            From a small carpet yarn making unit in Panipat with 15 workers in 1982 to a globally well-known premier manufacturer & exporter of home fashion, Vini Décor has come a long way.    <br />
                            Currently, Vini Décor has turned into an industry leader, directly or indirectly supporting so many families, and supplying yarns & rugs to the domestic and international market with its own R&D cell. <br />
                            Our unit complies with the standards of some of the world's most demanding customers. VINI DECOR, celebrating 40 years of inventiveness and dependability, aspires to be an industry leader in floor fashion. And we hope to do so by ongoing innovation, world-class customer service, and an unquenchable hunger for excellence across the board. <br />
                            Our rates are quite competitive as a Yarn and Carpet Manufacturer and Exporter, which help us compete in the market and extend our products with mutual benefit. We can create any pattern in Hand-Tufted, Hand-Loom, and Hand-Woven fabrics at cost-effective pricing for our customers. We are convinced that our dependable supply sources will provide a solid foundation for our future commercial partnership.
                        </p>

                    </div>

                </div>
            </div>



            {/* Our Teams=== */}
            <section className="team mt-5">
                <div className="believe_text text-center pb-5">
                    <h5 class="mb-5">MANAGEMENT</h5>
                    <p> VINI DECOR is a family-owned establishment that works in alignment with the family values of cooperation and accountability. Each Member has a clearly defined role that is as follows: </p>
                </div>
                <div className="row container mx-auto ">
                    <div className="col-lg-4 rounded mb-4">
                        <div class="card" >
                            <img src={require('../Aboutus/images/kamal-garg.png').default} class="card-img-top" alt="..." />
                            <div class="card-body p-3">
                                <h2 class="card-title">Mr. Kamal Garg</h2>
                                <h5>Founder</h5>
                                <em class="card-text text-white">kamalgarg@vinidecor.in</em>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 rounded mb-4 ">
                        <div class="card" >
                            <img src={require('../Aboutus/images/vishal1.jpg').default} class="card-img-top" alt="..." />
                            <div class="card-body p-3">
                                <h2 class="card-title">Mr.Vishal Garg</h2>
                                <h5>MD & Creative Director</h5>
                                <em class="card-text">info@vinidecor.in</em>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 rounded mb-4 ">
                        <div class="card" >
                            <img src={require('../Aboutus/images/nitin2.jpg').default} class="card-img-top" alt="..." />
                            <div class="card-body p-3">
                                <h2 class="card-title">Mr.Nitin Garg</h2>
                                <h5>Managing Director</h5>
                                <em class="card-text">nitingarg@vinidecor.in</em>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Our Teams=== */}


                    <div className="d-flex justify-content-center py-5">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/7w0G8ULWZw4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>




            {/* NewsLetter */}

            <FooterContact />

            {/* NewsLetter */}

            {/* Footer==== */}

            <Footer />
            {/* Footer==== */}



        </div>
    )


}
export default AboutUs
