export default [
    {
        Id: 1,
        imgsrc: require("../../images/showroom/(1).jpeg").default,
        spinner:"text-primary",
    },
    {
        Id: 2,
        imgsrc: require( "../../images/showroom/(1).jpg").default,
        spinner:"text-secondary",
    },
    {
        Id: 3,
        imgsrc: require( "../../images/showroom/(2).jpeg").default,
        spinner:"text-success",
    },
    {
        Id: 4,
        imgsrc: require( "../../images/showroom/(2).jpg").default,
        spinner:"text-danger",
    },
    {
        Id: 5,
        imgsrc: require( "../../images/showroom/(3).jpeg").default,
        spinner:"text-warning",
    },
    {
        Id: 6,
        imgsrc: require( "../../images/showroom/(3).jpg").default,
        spinner:"text-info",
    },
    {
        Id: 7,
        imgsrc: require( "../../images/showroom/(4).jpeg").default,
        spinner:"text-light",
    },
    // {
    //     Id: 8,
    //     imgsrc: require( "../../images/showroom/(4).jpg").default,
    //     spinner:"text-dark",
    // },
    {
        Id: 9,
        imgsrc: require( "../../images/showroom/(5).jpeg").default,
        spinner:"text-warning",
    },
    // {
    //     Id: 10,
    //     imgsrc: require( "../../images/showroom/(5).jpg").default,
    //     spinner:"text-success",
    // },
    {
        Id: 11,
        imgsrc: require( "../../images/showroom/(6).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 12,
        imgsrc: require( "../../images/showroom/(7).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 13,
        imgsrc: require( "../../images/showroom/(8).jpeg").default,
        spinner:"text-info",
    },
    // {
    //     Id: 14,
    //     imgsrc: require( "../../images/showroom/(7).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 15,
    //     imgsrc: require( "../../images/showroom/(8).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 16,
    //     imgsrc: require( "../../images/showroom/(9).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 17,
    //     imgsrc: require( "../../images/showroom/(10).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 18,
    //     imgsrc: require( "../../images/showroom/(11).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 19,
    //     imgsrc: require( "../../images/showroom/(12).jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 20,
    //     imgsrc: require( "../../images/showroom/(20).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 21,
    //     imgsrc: require( "../../images/showroom/(21).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 22,
    //     imgsrc: require( "../../images/showroom/(22).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 23,
    //     imgsrc: require( "../../images/showroom/(23).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 24,
    //     imgsrc: require( "../../images/showroom/(24).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 25,
    //     imgsrc: require( "../../images/showroom/(25).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 26,
    //     imgsrc: require( "../../images/showroom/(26).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 27,
    //     imgsrc: require( "../../images/showroom/(27).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 28,
    //     imgsrc: require( "../../images/showroom/(28).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 29,
    //     imgsrc: require( "../../images/showroom/(29).jpeg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 30,
    //     imgsrc: require( "../../images/showroom/(30).JPG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 32,
    //     imgsrc: require( "../../images/showroom/(32)-min.jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 33,
    //     imgsrc: require( "../../images/showroom/(33)-min.jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 34,
    //     imgsrc: require( "../../images/showroom/(34)-min.jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 35,
    //     imgsrc: require( "../../images/showroom/(35)-min.jpg").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 36,
    //     imgsrc: require("../../images/showroom/(36)-min.JPG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 37,
    //     imgsrc: require("../../images/showroom/(37)-min.JPG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 38,
    //     imgsrc: require("../../images/showroom/(38)-min.JPG").default,
    //     spinner:"text-info",
    // },
    // {
    //     Id: 39,
    //     imgsrc: require("../../images/showroom/(39)-min.jpeg").default,
    //     spinner:"text-info",
    // },
   

   

];
