export default [
    {
        Id: 1,
        imgsrc: require("../images/BRAID_RUG/rug1-min.jpg").default,
        // imgsrc: require("../images/HANDWOVEN PUNJA RUGS/handwoven/1.jpg").default,
        spinner:"text-primary",
        code:'VDST-101',
    },
    {
        Id: 2,
        imgsrc: require("../images/BRAID_RUG/rug2-min.jpg").default,
        spinner:"text-secondary",
        code:'VDST-204',
    },
    {
        Id: 3,
        imgsrc: require("../images/BRAID_RUG/rug3-min.jpg").default,
        spinner:"text-success",
        code:'VDST-204',
    },
    {
        Id: 4,
        imgsrc: require("../images/BRAID_RUG/rug4-min.jpg").default,
        spinner:"text-danger",
        code:'VDST-211',
    },
    {
        Id: 5,
        imgsrc: require("../images/BRAID_RUG/rug5-min.jpg").default,
        spinner:"text-warning",
        code:'VDST-211',
    },
    {
        Id: 6,
        imgsrc: require("../images/BRAID_RUG/rug6-min.jpg").default,
        spinner:"text-info",
        code:'VDST-211',
    },
    {
        Id: 7,
        imgsrc: require("../images/BRAID_RUG/rug7-min.jpg").default,
        spinner:"text-light",
        code:'VDST-1011',
    },
    {
        Id: 8,
        imgsrc: require("../images/BRAID_RUG/rug8-min.jpg").default,
        spinner:"text-dark",
        code:'VDST-1012',
    },
    {
        Id: 9,
        imgsrc: require("../images/BRAID_RUG/rug9-min.jpg").default,
        spinner:"text-warning",
        code:'VDST-1013',
    },
    {
        Id: 10,
        imgsrc: require("../images/BRAID_RUG/rug10-min.jpg").default,
        spinner:"text-success",
        code:'VDST-1014',
    },
    {
        Id: 11,
        imgsrc: require("../images/BRAID_RUG/rug11-min.jpg").default,
        spinner:"text-info",
        code:' VDST-1015',
    },
    {
        Id: 12,
        imgsrc: require("../images/BRAID_RUG/rug12-min.jpg").default,
        spinner:"text-info",
        code:'VDST-1016',
    },
    {
        Id: 13,
        imgsrc: require("../images/BRAID_RUG/rug13-min.jpg").default,
        spinner:"text-info",
        code:' VDST-1017',
    },
    {
        Id: 14,
        imgsrc: require("../images/BRAID_RUG/rug14-min.jpg").default,
        spinner:"text-info",
        code:'VDST-1018',
    },
    {
        Id: 15,
        imgsrc: require("../images/BRAID_RUG/rug15-min.jpg").default,
        spinner:"text-info",
        code:' VDST-1019',
    },
    {
        Id: 16,
        imgsrc: require("../images/BRAID_RUG/rug16-min.jpg").default,
        spinner:"text-info",
        code:'VDST-1020',
    },
   

];
