import React, { useEffect, useState } from 'react';
// import React from 'react';
// import "../../src/index.css";
// import "../Home/review.css";
// import "../Home/Home.css";
// import "../Home/Slider/jquery.easy_slides.css";
// import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import { Link } from 'react-router-dom';
import '../Home/cards.css';
import ProductRange from '../extracomponents/ProductRange';
import FooterContact from '../extracomponents/FooterContact';
import Footer from '../Footer/Footer';
import userimg from "../images/user.png"
import { useHistory } from 'react-router-dom';


const Home = () => {

    const history = useHistory();


    const [spinner, setspinner] = useState(true);


    useEffect(() => {
        window.scrollTo(0, 0)
        setTimeout(() => {
            setspinner(false)
        }, 500);

    }, []);


    useEffect(() => {

        // review

        var testim = document.getElementById("testim"),
            testimDots = Array.prototype.slice.call(document.getElementById("testim-dots").children),
            testimContent = Array.prototype.slice.call(document.getElementById("testim-content").children),
            testimLeftArrow = document.getElementById("left-arrow"),
            testimRightArrow = document.getElementById("right-arrow"),
            testimSpeed = 4500,
            currentSlide = 0,
            currentActive = 0,
            testimTimer,
            touchStartPos,
            touchEndPos,
            touchPosDiff,
            ignoreTouch = 30;
        ;

        window.onload = function () {

            // Testim Script
            function playSlide(slide) {
                for (var k = 0; k < testimDots.length; k++) {
                    testimContent[k].classList.remove("active");
                    testimContent[k].classList.remove("inactive");
                    testimDots[k].classList.remove("active");
                }

                if (slide < 0) {
                    slide = currentSlide = testimContent.length - 1;
                }

                if (slide > testimContent.length - 1) {
                    slide = currentSlide = 0;
                }

                if (currentActive != currentSlide) {
                    testimContent[currentActive].classList.add("inactive");
                }
                testimContent[slide].classList.add("active");
                testimDots[slide].classList.add("active");

                currentActive = currentSlide;

                clearTimeout(testimTimer);
                testimTimer = setTimeout(function () {
                    playSlide(currentSlide += 1);
                }, testimSpeed)
            }

            testimLeftArrow.addEventListener("click", function () {
                playSlide(currentSlide -= 1);
            })

            testimRightArrow.addEventListener("click", function () {
                playSlide(currentSlide += 1);
            })

            for (var l = 0; l < testimDots.length; l++) {
                testimDots[l].addEventListener("click", function () {
                    playSlide(currentSlide = testimDots.indexOf(this));
                })
            }

            playSlide(currentSlide);

            // keyboard shortcuts
            document.addEventListener("keyup", function (e) {
                switch (e.keyCode) {
                    case 37:
                        testimLeftArrow.click();
                        break;

                    case 39:
                        testimRightArrow.click();
                        break;

                    case 39:
                        testimRightArrow.click();
                        break;

                    default:
                        break;
                }
            })

            testim.addEventListener("touchstart", function (e) {
                touchStartPos = e.changedTouches[0].clientX;
            })

            testim.addEventListener("touchend", function (e) {
                touchEndPos = e.changedTouches[0].clientX;

                touchPosDiff = touchStartPos - touchEndPos;

                console.log(touchPosDiff);
                console.log(touchStartPos);
                console.log(touchEndPos);


                if (touchPosDiff > 0 + ignoreTouch) {
                    testimLeftArrow.click();
                } else if (touchPosDiff < 0 - ignoreTouch) {
                    testimRightArrow.click();
                } else {
                    return;
                }

            })
        }


    }, [])



    return (
        <div style={{ overflow: 'hidden' }}>

            {/* <!-- <Header Start============= --> */}
            <Header />
            {/* <!-- <Header End============= --> */}


            {/* video=== */}
            <div id="homegb" style={{ height: '100vh', position: 'relative' }}>
                <video src={require('../Home/images/vinivini.mp4').default} muted autoPlay loop width="100%"></video>
                <div className="overlay_video text-center    d-flex justify-content-center align-items-center flex-column text-white">
                    <h1>Vini Decor | Keep Inventing</h1>
                    <h1 style={{ fontSize: "2rem" }}>Truly Unique Handmade Rugs with countless possibilities of designs</h1>
                    {/* <a href="#" className="btn btn-light px-5 button">Read More</a> */}
                    {/* <div className='btn1_bg'> */}
                    <Link to="/whats-new" className="btn1 btn-4"><span>What's New</span></Link>
                    {/* </div>  */}
                </div>
            </div>
            {/* video=== */}






            {/* Our products=== */}

            {/* <section className="product_card bg-light py-5">
                <div className="text-center pt-5">
                    <h2>OUR PRODUCTS</h2>
                    <b>6 different shapes with distinctive designs to make any ambience exquisite and refined.</b>
                </div>
                <div className="container p-0">
                    <div className="row">
                        <div className="col-lg-4">
                            <div class="card">
                                <div class="bottom">
                                    <h4 class="name">HandTufed Carpet</h4>
                                    <div class="profession-wrapper">
                                        <p class="profession">
                                            Carpet
                                        </p>

                                        <button class="btn">

                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill-rule="evenodd" clip-rule="evenodd">
                                                <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                                            </svg>
                                            <a href="#"><i class="fas fa-long-arrow-alt-right"></i></a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card2">
                                <div class="bottom">
                                    <h4 class="name">Handloom texture Carpets</h4>
                                    <div class="profession-wrapper">
                                        <p class="profession">
                                            Carpets
                                        </p>

                                        <button class="btn">

                                            <a href="#"><i class="fas fa-long-arrow-alt-right"></i></a>

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card3">
                                <div class="bottom">
                                    <h4 class="name">Jaquard Carpets</h4>
                                    <div class="profession-wrapper">
                                        <p class="profession">
                                            Carpets
                                        </p>

                                        <button class="btn">

                                            <a href="#"><i class="fas fa-long-arrow-alt-right"></i></a>

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container p-0">
                    <div className="row">
                        <div className="col-lg-4">
                            <div class="card4">
                                <div class="bottom">
                                    <h4 class="name">Leather Flooring</h4>
                                    <div class="profession-wrapper">
                                        <p class="profession">
                                            Carpets
                                        </p>
                                        <button class="btn">

                                            <a href="#"><i class="fas fa-long-arrow-alt-right"></i></a>

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card5">
                                <div class="bottom">
                                    <h4 class="name">Handmade Killims</h4>
                                    <div class="profession-wrapper">
                                        <p class="profession">
                                            Web designer
                                        </p>

                                        <button class="btn">
                                            <a href="#"><i class="fas fa-long-arrow-alt-right"></i></a>

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card6">
                                <div class="bottom">
                                    <h4 class="name">Handmade Punja Weaves</h4>
                                    <div class="profession-wrapper">
                                        <p class="profession">
                                            Carpets
                                        </p>

                                        <button class="btn">
                                            <a href="#"><i class="fas fa-long-arrow-alt-right"></i></a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* Our products=== */}



            <div class="b-range-bnr l-block-space-bottom js-animate-in h-animate-in-slide-up js-animate-in--is-active">
                <div class="b-range-bnr__inner">
                    <h3 class="b-range-bnr__heading">Our Rugs</h3>
                    <p class="b-range-bnr__subheading">With an ever evolving stock, we only feature 25% of pieces online</p>
                    <div class="b-range-bnr__info">
                        <p>Simply <Link to="/contact" style={{textDecoration:"none"}} ><strong style={{color:"black",textDecoration:"none" }}><b style={{fontSize:"1.2rem"}}>click here</b></strong></Link> for any help with your rug requirements</p>
                    </div>
                    {/* <Link to="/contact" class="b-range-bnr__overlay-link"></Link> */}
                    <svg class="b-range-bnr__icon"><use href="#icon-arrow-right"></use></svg>

                    {/* <a href="#"><i class="fas fa-long-arrow-alt-right"></i></a> */}


                </div>
            </div>



            {/* Timeline======= */}
            <div className="text-center pt-5">
                <h2>OUR JOURNEY</h2>
                {/* <b>6 different shapes with distinctive designs to make any ambience exquisite and refined.</b> */}
            </div>
            <section className="cd-horizontal-timeline px-0 pb-5">

                <div className="timeline">
                    <div className="events-wrapper">
                        <div className="events">
                            <ol>
                                <li><a href="#0" data-date="16/01/1987" className="selected">1982</a></li>
                                <li><a href="#0" data-date="28/02/1993">1994</a></li>
                                <li><a href="#0" data-date="20/04/2001">2003</a></li>
                                <li><a href="#0" data-date="20/05/2006">2007</a></li>
                                <li><a href="#0" data-date="09/07/2010">2010</a></li>
                                <li><a href="#0" data-date="30/08/2014">2014</a></li>
                                <li><a href="#0" data-date="15/09/2015">2015</a></li>
                                <li><a href="#0" data-date="01/11/2016">2016</a></li>
                                <li><a href="#0" data-date="10/12/2017">2017</a></li>
                                <li><a href="#0" data-date="19/01/2019">2019</a></li>
                                <li><a href="#0" data-date="03/03/2020">2020</a></li>
                                <li><a href="#0" data-date="03/03/2021">2021</a></li>
                            </ol>

                            <span className="filling-line" aria-hidden="true"></span>
                        </div>
                    </div>

                    <ul className="cd-timeline-navigation">
                        {/* <li><a href="#0" className="prev inactive"></a></li> */}
                        <li><a href="#0" className="prev inactive"></a></li>
                        <li><a href="#0" className="next">Next</a></li>
                    </ul>
                </div>

                <div className="events-content">
                    <ol>
                        <li className="selected" data-date="16/01/1987">
                            <h2>THE FOUNDING YEAR</h2>
                            {/* <em>January 16th, 1982</em> */}
                            <em>1982</em>
                            <p>
                                Our father and mentor, Late Sh. Kamal Kishore Garg started his own firm with one spinning plant in the heart of the handloom city of Panipat, 90 kilometers from India's capital. In the next two years, he worked to add 2 more Plants with almost double the space of land and buildings
                            </p>
                        </li>

                        <li data-date="28/02/1993">
                            <h2>VISHAL WOOLLEN MILLS </h2>
                            <em>1994</em>
                            <p>
                                Added New Venture in the brand name of Vishal Woollen Mills, one of the best carpet yarn manufacturers in India now. Over the next 6 years, strategized to add 2 More Spinning plants of advanced technology imported quality.
                            </p>
                        </li>

                        <li data-date="20/04/2001">
                            <h2>IN HOUSE COTTON YAR SPINNING PLANT</h2>
                            <em>2003</em>
                            <p>
                                Supplemented the endeavor with a New Venture of Cotton Yarn Spinning Plant with almost 2,00,000 SqFt built up area with a spinning capacity of 10,000 kgs of yarn per day. Further supplied the Blanket Shoddy and Acrylic Yarns Plant & Machinery with a total yarn spinning capacity of 20,000 Kg per day including woolen, cotton, shoddy and acrylic yarns.
                            </p>
                        </li>

                        <li data-date="20/05/2006">
                            <h2>HANDING OFF THE REIGNS</h2>
                            <em>2007</em>
                            <p>
                                Helping hands of family, Mr. Vishal Garg and Nitin Garg joined the family business and continued the steps to success.
                            </p>
                        </li>

                        <li data-date="09/07/2010">
                            <h2>INTRODUCTION TO RUGS</h2>
                            <em>2010</em>
                            <p>
                                Started Carpet Manufacturing and Handmade Pebbles Rugs which was popularly deemed to be the revolution of the carpet industry.
                            </p>
                        </li>

                        <li data-date="30/08/2014">
                            <h2>FOUNDING VINI DECOR</h2>
                            <em>2014</em>
                            <p>
                                Started New Venture in the brand name of VINI DECOR, with the in-house capacity of all under one roof, from direct import of raw wool to the spinning of carpet yarns, dying joint ventures, in-house Pitloom’s, Punja Looms, Handlooms and Hand -Tufted carpet production facility, to finishing and final packing and movement of the shipment.
                            </p>
                        </li>

                        <li data-date="15/09/2015">
                            <h2>FIRST EXHIBITION</h2>
                            <em>2015</em>
                            <p>
                                First International Exhibition participation at Domotex, Germany with great success.
                            </p>
                        </li>

                        <li data-date="01/11/2016">
                            <h2>SUBSEQUENT EXHIBITIONS</h2>
                            <em>2016</em>
                            <p>
                                Participations at different exhibitions in Dubai, New Delhi, Varanasi, UK, USA.
                            </p>
                        </li>

                        <li data-date="10/12/2017">
                            <h2>GOVERNMENT RECOGNITION</h2>
                            <em>2017</em>
                            <p>
                                Achieved 5 Star Government Recognised Export House Status from the Govt. of India.
                            </p>
                        </li>
                        <li data-date="19/01/2019">
                            <h2>RECEIVED AWARDS</h2>
                            <em>2019</em>
                            <p>
                                Achieved 2nd Best Design Carpet Award All over India.
                            </p>
                        </li>
                        <li data-date="19/01/2020">
                            <h2>RECEIVED AWARDS</h2>
                            <em>2020</em>
                            <p>
                                Achieved 2nd Best Design Carpet Award All over India.
                            </p>
                        </li>

                        <li data-date="03/03/2020">
                            <h2>RECEIVED AWARDS</h2>
                            <em>2020</em>
                            <p>
                                Won 1st Best Design Carpet Award All over India.
                            </p>
                        </li>

                        <li data-date="03/03/2021">
                            <h2>JOURNEY HENCEFORTH</h2>
                            <em>2021</em>
                            <p>
                                Achieved double turnover target and hard work for further Success is continuing on & on…
                            </p>
                        </li>
                    </ol>
                </div>
            </section>


            {/* Timeline======= */}


            <ProductRange />



            {/* {Scroll Bar Cards}    */}


            {/* Text-SpotLight==== */}
            <section className="sect2 container-fluid py-5  " id="backgroundbottom" style={{ marginTop: '100px' }}>
                <div className="row px-3">
                    <div className="col-lg-4 col-12 ">
                    </div>
                    <div className="col-lg-4 col-12">
                    </div>
                    <div className="col-lg-4 col-12 text-right p-5" style={{ background: '#fff' }}>
                        {/* <h4>TEXTILE SPOTLIGHT</h4> */}
                        <h3>What's New</h3>
                        <p>Check out our latest collection of rugs and carpets and make your living space more homely with the charms of our affordable Decor!</p>
                        {/* <a href="#" className="btn text-white px-5" style={{ backgroundColor: '#85523A' }}>Shop this &nbsp; <i
                            className="fas fa-arrow-right"></i> </a> */}
                        {/* <div className='btn1_bg col-lg-6'> */}
                        <Link to="/whats-new" className="btn1 btn-4"><span>Explore</span></Link>
                        {/* </div> */}
                    </div>
                </div>

            </section>
            {/* Text-SpotLight==== */}


            {/* Animate Cards====== */}
            <section className="animate_cards p-0" style={{ height: '100%', marginTop: '100px', marginBottom: '100px' }}>

                <div className="row container-fluid p-lg-5 p-0 m-0">
                    <div className="text-start">
                        <h2>OUR COMPANY</h2>
                    </div>
                    <div className="col-lg-4  col-12 bg-white" style={{ position: 'relative' }}>
                        <div className="newoverlay" style={{ position: 'absolute', width: '100%', height: '100%' }}>
                        </div>
                        <div className="card  text-white p-0"  style={{display: 'flex'}}>
                            <img src={require('../Home/images/animatecard1.jpg').default} alt="" />
                            <div className="card-img-overlay1 p-5 p-lg-5">
                                {/* <h4 className="card-title">A</h4> */}
                                <h3 className="card-text"> ABOUT US</h3>

                                <Link to="/aboutus" className="btn1 btn-4"><span>Know More</span></Link>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4 col-12 bg-white" style={{ position: 'relative' }}>
                        <div className="newoverlay" style={{ position: 'absolute', width: '100%', height: '100%' }}>

                        </div>
                        <div className="card  text-white p-0"  style={{display: 'flex'}}>
                            <img src={require('../Home/images/animatecard2.jpg').default} alt="" />
                            <div className="card-img-overlay  p-5">
                                {/* <h2 className="card-text text-white">CLIENTS</h2> */}

                                <h4 className="card-title ">CLIENTS</h4>
                                {/* <h3 className="card-text"> dining in style</h3> */}
                            {/* <a href="#" className="btn1 btn-4"><span>Shop Now</span></a> */}
                            <Link to="/client" className="btn1 btn-4"><span>Know More</span></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 bg-white" style={{ position: 'relative' }}>
                        <div className="card  text-white p-0" style={{display: 'flex'}} >
                            <div className="newoverlay" style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.1)' }}>

                            </div>
                            <img src={require('../Home/images/animatecard3.jpg').default} alt="" />
                            <div className="card-img-overlay1 p-lg-5 p-5 bg-white text-dark" style={{ margin: '30px' }}>
                                {/* <h4 className="card-title">Exhibitions</h4> */}
                                <h3 className="card-text"> Exhibitions</h3>
                                {/* <a href="#" className="btn1 btn-4"><span>Shop Now</span></a> */}
                                <Link to="/exhibition" className="btn1 btn-4"><span>Know More</span></Link>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* Animate Cards====== */}



            {/* <!-- slider==== --> */}
            {/* <div className="slider slider_one_big_2 mb-5">
                <div>
                    <img style={{ position: 'relative' }} src={require('../Home/images/carpet1.jpg').default} alt="" />
                    <h1 style={{ position: 'absolute ', top: '50%', left: '50%', transform: 'translate(-50%)', color: '#fff' }}>carpet value 1</h1>
                    
                </div>
                <div>
                    <img style={{ position: 'relative' }} src={require('../Home/images/carpet2.jpg').default} alt="" />
                    <h1 style={{ position: 'absolute ', top: '50%', left: '50%', transform: 'translate(-50%)', color: '#fff' }}>carpet value 2</h1>
                    
                </div>
                <div>
                    <img style={{ position: 'relative' }} src={require('../Home/images/carpet3.jpg').default} alt="" />
                    <h1 style={{ position: 'absolute ', top: '50%', left: '50%', transform: 'translate(-50%)', color: '#fff' }}>carpet value 3</h1>
                    
                </div>
                <div>
                    <img style={{ position: 'relative' }} src={require('../Home/images/carpet4.jpg').default} alt="" />
                    <h1 style={{ position: 'absolute ', top: '50%', left: '50%', transform: 'translate(-50%)', color: '#fff' }}>carpet value 4</h1>
                    
                </div>
                <div>
                    <img style={{ position: 'relative' }} src={require('../Home/images/carpet5.jpg').default} alt="" />
                    <h1 style={{ position: 'absolute ', top: '50%', left: '50%', transform: 'translate(-50%)', color: '#fff' }}>carpet value 5</h1>
                    

                </div>
                <div className="nav_indicators"></div>
            </div> */}
            {/* <!-- slider==== --> */}





            {/* Review Section====== */}
            <section className="reviewBox   pb-5">
                <div className="text-center mt-5">
                    <h2>REVIEWS</h2>
                </div>
                

                <section id="testim" class="testim py-5">

                    <div class="wrap">

                        <span id="right-arrow" class="arrow right fa fa-chevron-right"></span>
                        <span id="left-arrow" class="arrow left fa fa-chevron-left "></span>
                        <ul id="testim-dots" class="dots">
                            <li class="dot"></li>

                            <li class="dot"></li>

                            <li class="dot"></li>

                            <li class="dot"></li>

                            <li class="dot active"></li>
                        </ul>
                        <div id="testim-content" class="cont">


                            <div class="">
                                <div class="img"><img src={userimg} alt="" /></div>
                                <h2>Mr. Allen</h2>
                                <p style={{ color: "black" }}>Rarely do you find a product which ticks all the boxes of affordable, high quality and chic. Vini rugs and carpets are the first recommendations to all my clients.</p>
                            </div>

                            <div class="">
                                <div class="img"><img src={userimg} alt="" /></div>
                                <h2>Mr. Patrick</h2>
                                <p style={{ color: "black" }}>We have been in business with Vini Decor for a very long time. We never had a problem in regards to resolving our queries and late shipments.</p>
                            </div>

                            <div class="">
                                <div class="img"><img src={userimg} alt="" /></div>
                                <h2>Mr. Andreas</h2>
                                <p style={{ color: "black" }}>The products offered by Vini Decor are not only stylish but are also high quality. We usually sell out all of them as they are highly affordable too.</p>
                            </div>

                            <div class="active">
                                <div class="img"><img src={userimg} alt="" /></div>
                                <h2>Mrs. Tina</h2>
                                <p style={{ color: "black" }}>Vini Decor's rugs and carpets are of the highest quality and are widely loved by all our clients! We always look forward to their next shipment.</p>
                            </div>

                            <div class="inactive">
                                <div class="img"><img src={userimg} alt="" /></div>
                                <h2>Mr. Edwin</h2>
                                <p style={{ color: "black" }}> We, or our customers, have never had a bad experience with Vini Decor with respect to shipping or inquiries. They always offer the best rugs at the best prices with the best service!</p>
                            </div>

                        </div>

                    </div>

                </section>
            </section>
            {/* Review Section====== */}



            {/* Contact=== */}
            <FooterContact />
            <Footer />



        </div >
    )
}

export default Home
