import React from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import "./carpet.css"

const CarpetCare = () => {
    return <>
        <Header />
        {/* <div className="container"> */}
        <div class="row gx-0">
            <div class="col-12 ">
                <div class="" style={{ backgroundColor: '#85523A', borderRadius: "12px" }}>
                    <div class="p-2 text-white">
                        <div className="row gx-0">
                            <div className="col-12 text-white" >
                                <h4 class=" pb-3 text-center">CARPET CLEANING</h4>
                                <p class=" text-white">
                                    It's crucial to clean your carpet on a regular basis, in addition to dusting it frequently. Cleaning systems eliminate the oily, sticky soil that vacuums can't get rid of, and make your carpet look wonderful for longer.
                                    <br />
                                    Cleaning systems are designed to remove soils created by cooking gases, pollutants, and tracked-in dirt. Oily soil particles deposited on carpet fibers can lead to color dulling that is gradual but noticeable. The color isn't completely lost, but it is obscured by the film. Allowing this type of dirt to build and causes it to attract and hold the dry soil.
                                    <br />
                                </p>
                                <div class="col-12 p-3">
                                    <div class="" style={{ backgroundColor: '#fff', borderRadius: "12px" }}>
                                        <div class="p-2 text-white">
                                            <div className="row gx-0">
                                                <div className="col-md-4 col-12 aboutusmissionbg">
                                                    <img class="sideimg w-100" src="http://www.vinidecor.in/img/care_contant1.jpg" alt="" />

                                                </div>
                                                <div className="col-md-8 col-12 px-lg-5" >
                                                    <h4 class=" text-center " style={{ color: 'black' }}>HOW TO CLEAN CARPET</h4>
                                                    <p class="" style={{ color: 'black' }}>
                                                        <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Preventative Maintenance - walk-off mats placed at doorways prevent a majority of staining and soiling<br /><br />
                                                        <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Spot and Spill Removal - prompt attention to spills will prevent a stain from setting<br /><br />
                                                        <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Vacuuming - vacuum high-traffic areas to prevent matting<br /><br />
                                                        <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Hot water Extraction or steam cleaning - every 18 months or more depending on the traffic in your home, will remove oils and particulates that accelerate soiling<br /><br />
                                                        <h6>GET ON A CLEANING SCHEDULE</h6>
                                                        <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i>The cleaning task will be easier and more successful if the carpet is cleaned before it becomes too dirty. Depending on the number of occupants and the amount of activity, a normal household's carpet should be cleaned every 12 to 18 months.<br />
                                                        <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i>It's crucial to pick the right cleaning system. Some systems may leave residues, which promote re-soiling and defeat the aim of cleaning all together.
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <a href="#" class="btn btn-light mt-4">Read More</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 p-3">
                                    <div class="" style={{ backgroundColor: '#fff', borderRadius: "12px" }}>
                                        <div class="p-2 text-white">
                                            <div className="row gx-0">

                                                <div className="col-md-8 col-12 px-lg-5" >
                                                    <h4 class=" text-center " style={{ color: 'black' }}>VINI’s CHOICE: HOT WATER EXTRACTION</h4>
                                                    <p class="" style={{ color: 'black' }}>
                                                        <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> According to research, the hot water extraction system has the best cleaning capability. Although no steam is produced, this system is usually referred to as "steam cleaning." The procedure entails spraying a cleaning chemical into the carpet pile and then extracting the used solution and soil with water. This can be done with a truck-mounted equipment outside the house with just the hose and wand moved inside, or with a portable system taken inside.<br /><br />
                                                        <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Vini warranties require the homeowner to produce proof of regular hot water extraction (also known as "steam") cleaning by a professional cleaning service or a do-it-yourself system, utilising equipment recognised by the Carpet and Rug Institute's Seal of approval program.<br /><br />

                                                   
                                                    <h4 class=" pb-3 text-center" style={{ color: 'black' }}>PROFESSIONAL ADVANTAGES</h4>
                                                     
                                                        <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Carpet cleaners are an excellent option for your floor. The carpet should dry faster since their cleaning equipment has more extraction power than the rental units offered to individuals. When you clean your carpet, you're effectively forcing hot water onto it.
                                                        <br />
                                                        <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> True experts also know how to use the right equipment, use the right cleaning products for the job, and recognize the differences in fibers and carpet architecture. Furthermore, the detergent residue left on the carpet might result in quick re-soiling of the entire floor. Have you ever had your carpets cleaned only to discover that they become dirtier faster than they were before? That’s the reason.
                                                        <br />
                                                    </p>
                                                </div>
                                                <div className="col-md-4 col-12  text-center align-self-center">
                                                    <img class="sideimg w-100"   src="https://i.pinimg.com/originals/f2/05/68/f205689b1677433f63573e47957c40a8.jpg" alt="" height={400} width={390} />
                                                </div>
                                            </div>
                                            {/* <a href="#" class="btn btn-light mt-4">Read More</a> */}
                                        </div>
                                    </div>
                                </div>

                                <h4 class=" pb-3 text-center">WHAT ABOUT SPOT AND STAIN REMOVAL?</h4>
                                <p class=" text-white">
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Vini's Stain and Soil Carpet Remover is the only carpet cleaning product with the Good Housekeeping Seal, which has been trusted by consumers for over a century. It's even easy to use than most spot cleaners thanks to our proprietary method.
                                    <br />
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Routine spot removal- research has shown that many products sold for do-it-yourself spot removal clean poorly and their residues attract soil on the cleaned area rapidly afterward. The Carpet and Rug Institute's Seal of Approval program tests and certifies products that meet stringent standards and thus clean effectively, without damage to your carpet. In addition, products sold for do-it-yourself spot removal may contain optical brighteners and other agents that can cause a bleaching effect on your carpet and cause irreparable damage. You can refer to your product warranty guide for a list of common household spills and procedures you can follow.
                                    <br />
                                </p>
                                <h4 class=" pb-3 text-center">HOW TO GET ACNE MEDICATION OUT OF THE CARPET ?</h4>
                                <p class=" text-white">
                                    Note: Most acne medication used today contains benzoyl peroxide which is a powerful oxidizing agent, meaning that it is capable of bleaching dyed products. Despite your best efforts, it is possible that you may see a loss of colour in your carpet which is not covered under most residential carpet warranties. In this case follow the below steps:-
                                    <br />
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Scrape or blot up excess spill.
                                    <br />
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Apply detergent solution (1/4 teaspoon clear hand dishwashing soap and 1 cup warm water) with a damp towel; leave on for 3 to 5 minutes (blot, don't rub).
                                    <br />
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Apply water with a damp towel.
                                    <br />
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Blot, then finish with a weighted pad of white paper towels.
                                    <br />
                                </p>
                                IF STAIN IS STILL PRESENT, CONTINUE AS FOLLOWS:<br />
                                <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Blot, then finish with a weighted pad of white paper towels.
                                <br />
                                <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Rinse with warm water.
                                <br />
                                <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Blot with a dry white towel or paper towel.
                                <br />
                                <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Always rinse with water and blot dry as the last procedure.
                                <br />
                                PREVENTATIVE MAINTENANCE:<br />
                                <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i>APPLY - Apply all liquids to a clean white (not printed) towel or paper towel and use it to dampen the carpet. Never wet a carpet through to the backing.<br />
                                <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i>BLOT - Press straight down with a pad of clean white paper towels - never rub. Rubbing alters carpet texture. Then in a final blotting, leave 1/2" of paper towels on spot overnight with a weight.<br />
                                <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i>DAB - Apply a small amount of liquid to a clean, white towel or paper towel and touch lightly to the stained area of the carpet until dampened.<br />
                                <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i>SCRAPE - Gently scoop or scrape with the blunt edge of a spoon from the outside of the spill toward the center.<br />
                                <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i>WEIGHT - A weight 1/2" pad of white paper towels, left overnight, will absorb the last traces of liquid and foreign materials. Make sure the weighted object is colorfast and will not bleed onto the carpet.<br />
                                SOLUTIONS:<br />
                                <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i>DETERGENT - Mix 1/4 teaspoon clear, see-through hand dish-washing detergent with 1 cup warm - not hot - water.<br /><br />
                                

                                
                                <h4 class="card-title pb-3 text-center">PREVENTATIVE MAINTENANCE</h4>
                                <p class="card-text text-white">
                                    EASY, PROACTIVE STEPS WILL KEEP YOUR CARPET LOOKING GOOD OVER TIME.<br />
                                    DON'T TRACK DIRT INSIDE:
                                    <br />
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Placing walk-off mats outside all entrances will help? It absorbs soil and moisture and traps excessive dirt, sand, grit, oil, asphalt, or driveway sealer that might otherwise be tracked into the home. Clean mats on a regular basis (or sooner, when they need it) so they don’t become sources of soil themselves.
                                    <br />
                                    USE A QUALITY CARPET PAD:<br />
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> A good carpet pad not only gives better resilience and comfort underfoot, but it can also extend the life of your carpet, especially on stairs. Because some carpets carry warranties with specific density and thickness requirements, be sure and review your warranty before purchasing your pad. Explore varieties of carpet pads.
                                    <br />
                                    OCCASIONALLY MOVE HEAVY FURNITURE:<br />
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Moving your furniture on occasion won't just renew the feel of your room, it will also help avoid excessive pile crushing. Also consider using carpet protectors under the legs of tables, chairs, and other furniture to help distribute the weight. Damage can occur if you use chairs or appliances with rollers or casters without a chair pad designed specifically for carpet.
                                    <br />
                                    PROTECT CARPET WHEN MOVING FURNITURE:<br />
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i>When moving heavy wheeled furniture (pianos, buffets, etc.), prevent damage by placing a protective barrier of heavy cardboard or plywood between the wheels and the carpet.
                                    <br />
                                    CLEAN YOUR AREA RUGS:<br />
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i>If you use area rugs on your carpet, be sure to clean them regularly, and make sure you clean and restore the pile of the carpet underneath as well. Also, be sure to check area rugs for colorfastness before placing them on the carpet because the color in some rugs may bleed through. After cleaning your carpet, allow it to dry completely before replacing rugs.<br />
                                    REDUCE PERIODS OF DIRECT SUNLIGHT:<br />
                                    <i class="fas fa-check-double " style={{ color: '#10AC84' }}></i> Protect your carpet from prolonged periods of direct sunlight with blinds, shades, or awnings.

                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <Footer />

        {/* </div> */}

    </>;
};

export default CarpetCare;