export default [
    {
        Id: 1,
        imgsrc: require("../../images/client/(1).jpeg").default,
        spinner:"text-primary",
    },
    
    {
        Id: 3,
        imgsrc: require( "../../images/client/(3).jpeg").default,
        spinner:"text-success",
    },
    {
        Id: 4,
        imgsrc: require( "../../images/client/(4).jpeg").default,
        spinner:"text-danger",
    },
    {
        Id: 5,
        imgsrc: require( "../../images/client/(5).jpeg").default,
        spinner:"text-warning",
    },
    {
        Id: 6,
        imgsrc: require( "../../images/client/(6).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 7,
        imgsrc: require( "../../images/client/(7).jpeg").default,
        spinner:"text-light",
    },
    {
        Id: 8,
        imgsrc: require( "../../images/client/(8).jpeg").default,
        spinner:"text-dark",
    },
    {
        Id: 9,
        imgsrc: require( "../../images/client/(9).jpeg").default,
        spinner:"text-warning",
    },
    {
        Id: 10,
        imgsrc: require( "../../images/client/(10).jpeg").default,
        spinner:"text-success",
    },
    {
        Id: 11,
        imgsrc: require( "../../images/client/(11).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 12,
        imgsrc: require( "../../images/client/(12).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 13,
        imgsrc: require( "../../images/client/(13).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 14,
        imgsrc: require( "../../images/client/(14).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 15,
        imgsrc: require( "../../images/client/(15).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 16,
        imgsrc: require( "../../images/client/(16).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 17,
        imgsrc: require( "../../images/client/(17).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 18,
        imgsrc: require( "../../images/client/(18).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 19,
        imgsrc: require( "../../images/client/(19).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 20,
        imgsrc: require( "../../images/client/(20).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 21,
        imgsrc: require( "../../images/client/(21).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 22,
        imgsrc: require( "../../images/client/(22).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 23,
        imgsrc: require( "../../images/client/(23).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 24,
        imgsrc: require( "../../images/client/(24).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 25,
        imgsrc: require( "../../images/client/(25).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 26,
        imgsrc: require( "../../images/client/(26).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 27,
        imgsrc: require( "../../images/client/(27).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 28,
        imgsrc: require( "../../images/client/(28).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 29,
        imgsrc: require( "../../images/client/(29).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 30,
        imgsrc: require( "../../images/client/(30).jpeg").default,
        spinner:"text-info",
    },
    {
        Id: 2,
        imgsrc: require( "../../images/client/(2).jpeg").default,
        spinner:"text-secondary",
    },

   

];
