import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import Home from './Home/Home';
// import Contact from './Contact/Contact';
// import Product from './Products/Products';
// import About from './Aboutus/About';
// import Cart from './Cart/Cart';
// import CheckOut from './Checkout/CheckOut';
// import Login from './Login/Login';

import reportWebVitals from './reportWebVitals';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    {/* <About/> */}
    <App />
    {/* <Contact/> */}
    {/* <Product/> */}
      {/* <Cart/> */}
    {/* <CheckOut/> */}
    {/* <Login/> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
