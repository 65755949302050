export default [
    {
        Id: 1,
        imgsrc: require("../images/ACCESSORIES/POUFS/pouf1.JPG").default,
        spinner:"text-primary",
        code:'VDPF-221 ',
    },
    {
        Id: 2,
        imgsrc: require("../images/ACCESSORIES/POUFS/pouf2.JPG").default,
        spinner:"text-secondary",
        code:'VDPF-221',
    },
    // {
    //     Id: 3,
    //     imgsrc: require("../images/ACCESSORIES/POUFS/pouf3.JPG").default,
    //     spinner:"text-success",
    //     code:'VDPF-221 ',
    // },
    {
        Id: 4,
        imgsrc: require("../images/ACCESSORIES/POUFS/pouf4.JPG").default,
        spinner:"text-danger",
        code:'VDPF-311 ',
    },
    {
        Id: 5,
        imgsrc: require("../images/ACCESSORIES/POUFS/pouf5.JPG").default,
        spinner:"text-warning",
        code:'VDPF-311 ',
    },
    {
        Id: 6,
        imgsrc: require("../images/ACCESSORIES/POUFS/pouf6.JPG").default,
        spinner:"text-info",
        code:'VDPF-311 ',
    },
    // {
    //     Id: 7,
    //     imgsrc: require("../images/ACCESSORIES/POUFS/pouf7.JPG").default,
    //     spinner:"text-light",
    //     code:'VDPF-311 ',
    // },
    {
        Id: 8,
        imgsrc: require("../images/ACCESSORIES/POUFS/pouf8.JPG").default,
        spinner:"text-dark",
        code:'VDPF-312 ',
    },
    {
        Id: 9,
        imgsrc: require("../images/ACCESSORIES/POUFS/pouf9.JPG").default,
        spinner:"text-warning",
        code:'VDPF-312 ',
    },
    // {
    //     Id: 10,
    //     imgsrc: require("../images/ACCESSORIES/POUFS/pouf9.JPG").default,
    //     spinner:"text-success",
    //     code:'VDPF-312 ',
    // },
   

];
