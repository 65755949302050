import React,{useEffect, useState} from 'react'
import Header from '../Header/Header';
import axios from "axios"
import Footer from '../Footer/Footer';
import FooterContact from '../extracomponents/FooterContact';

export const Contact = () => {
    const [firstname, setFirstName] = useState();
    const [lastname, setLastName] = useState();
    const [phone, setphone] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])


    const handleSubmit = async (e) => {
        e.preventDefault();
        alert("form submitted")
        axios.get(`http://api.digisidekick.com/api/Mail?Email=nitingarg@vinidecor.in&Firstname=${firstname}&Lastname=${lastname}&Number=${phone}&Usermial=${email}&UserMessage=${message}&other=null`,)
            .then((res) => {
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
                console.log(res.data)
                
            }).catch((error) => {
                // alert("Form has been submitted successfully, Our team will contact you in 24 hours.");
                console.log(error)
            });
            setFirstName("");
            setLastName("");
            setphone('');
            setEmail("")
            setMessage("");
            // history.push("/thank")
    }

  
    return (
        <div>
            <Header/>
          
            {/* <!-- <Header End============= --> */}

            {/* Contact For */}
            <section class="contact px-2">
                <div className="row container mx-auto px-1 px-lg-5 p-5 g-5">
                    <div className="col-lg-7 bg-white p-5 px-2 px-lg-5  mx-auto rounded" style={{ boxShadow: '1px 0 50px 0 rgb(45 62 80 / 6%)' }}>
                        <h1 class="display-5" >Contact us with your queries below.</h1>
                        <small>If you have any queries regarding any products or purchases, please feel free to contact us using the form below.</small>
                        <form class="py-2" method="post">
                            <div class="row">
                                <div class="col-lg-6">
                                    <input type="text" class="form-control" placeholder="First Name" aria-label="First name" id="name1" value={firstname} onChange={(e) => setFirstName(e.target.value)} required/>
                                </div>
                                <div class="col-lg-6">
                                    <input type="text" class="form-control" placeholder="Last Name" aria-label="Last name" id="name1" value={lastname} onChange={(e) => setLastName(e.target.value)} required/>
                                </div>
                            </div>
                            <div class="form-row my-3">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Email" aria-label="Email" id="name1" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                                </div>
                            </div>
                            <div class="form-row my-3">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Phone Number" aria-label="Phone Number" id="name1" value={phone} onChange={(e) => setphone(e.target.value)} required/>
                                </div>
                            </div>
                            <div class="form-floating my-3">
                                <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: '100px' }} value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                                <label for="floatingTextarea2">Message</label>
                            </div>


                        <a href="#" id="submit" className="btn1 btn-4 col-lg-12 col-12" onClick={handleSubmit}><span>Submit</span></a>
                        </form>
                        {/* <a href="#" class="btn col-lg-12 " style={{ backgroundColor: '#85523A' }}><b class="text-white">Submit</b></a> */}
                    </div>
                    <div className="col-lg-4 bg-white p-5 mx-2 rounded" style={{ boxShadow: '1px 0 50px 0 rgb(45 62 80 / 6%)', padding: ' 15px 54px' }}>
                        <div className="upper_text">
                            <h2>Address</h2>
         
                           <span style={{fontWeight:'bold'}}>Head Office: </span> <p > Vini Decor Kabri Road, Kuldeep Nagar, Panipat-132103, Haryana (India)</p>
                           <span style={{fontWeight:'bold'}}>Branch Office: </span> <p > Vini Decor (Unit-2),Alipur Road,Vill. Kohand, Panipat-132103, Haryana (India)</p>

                        </div>
                        <div className="lower_text">
                            <h2>WhatsApp us at,</h2>
                            <p  className='my-0'>+91-999-100-0004</p>
                            <p>+91-981-205-0822</p>
                            <h2>Mail us at,</h2>
                            <p>nitingarg@vinidecor.in <br />info@vinidecor.in </p>

                        </div>
                    </div>
                </div>
            </section>
            {/* Contact For */}
            



            <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3475.3882409926364!2d76.93918341457825!3d29.417447254873828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390dd9677618f9bb%3A0x375934f2e698b8c3!2sVINI%20DECOR!5e0!3m2!1sen!2sin!4v1644566686119!5m2!1sen!2sin" width="100%" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy"></iframe>
            </div>


            <FooterContact/>

            {/* NewsLetter */}
            
            {/* NewsLetter */}
            <Footer/>
          

        </div>
    )
}
export default Contact