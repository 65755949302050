export default [
    {
        Id: 1,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(1).jpg").default,
        spinner:"text-primary",
         code:'VDPT-7141 ',
    },
    {
        Id: 2,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(2).jpg").default,
        spinner:"text-secondary",
         code:'VDPT-7064 ',
    },
    {
        Id: 3,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(3).jpg").default,
        spinner:"text-success",
         code:'VDPT-7082  ',
    },
    {
        Id: 4,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(4).jpg").default,
        spinner:"text-danger",
         code:'VDPT-7082 ',
    },
    {
        Id: 5,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(5).jpg").default,
        spinner:"text-warning",
         code:'VDPT-7086 ',
    },
    {
        Id: 6,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(6).jpg").default,
        spinner:"text-info",
         code:'VDPT-7086 ',
    },
    {
        Id: 7,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(7).jpg").default,
        spinner:"text-light",
         code:'VDPT-7088',
    },
    {
        Id: 8,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(8).jpg").default,
        spinner:"text-dark",
         code:'VDPT-7088',
    },
    {
        Id: 9,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(9).jpg").default,
        spinner:"text-warning",
         code:'VDPT-7089 ',
    },
    {
        Id: 10,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(10).jpg").default,
        spinner:"text-success",
         code:'VDPT-7089 ',
    },
    {
        Id: 11,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(11).jpg").default,
        spinner:"text-info",
         code:'VDPT-7090 ',
    },
    {
        Id: 12,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(12).jpg").default,
        spinner:"text-info",
         code:'VDPT-7090 ',
    },
    {
        Id: 13,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(13).jpg").default,
        spinner:"text-info",
         code:'VDPT-7091  ',
    },
    {
        Id: 14,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(14).jpg").default,
        spinner:"text-info",
         code:'VDPT-7091  ',
    },
    {
        Id: 15,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(15).jpg").default,
        spinner:"text-info",
         code:'VDPT-7095 ',
    },
    {
        Id: 16,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(16).jpg").default,
        spinner:"text-info",
         code:'VDPT-7095 ',
    },
    {
        Id: 17,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(17).jpg").default,
        spinner:"text-info",
         code:'VDPT-7108 ',
    },
    {
        Id: 18,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(18).jpg").default,
        spinner:"text-info",
         code:'VDPT-7109  ',
    },
    {
        Id: 19,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(19).jpg").default,
        spinner:"text-info",
         code:'VDPT-7109  ',
    },
    {
        Id: 20,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(20).jpg").default,
        spinner:"text-info",
         code:'VDPT-7120  ',
    },
    {
        Id: 21,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(21).jpg").default,
        spinner:"text-info",
         code:'VDPT-7120 ',
    },
    {
        Id: 22,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(22).jpg").default,
        spinner:"text-info",
         code:'VDPT-7128 ',
    },
    {
        Id: 23,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(23).jpg").default,
        spinner:"text-info",
         code:'VDPT-7132 ',
    },
    {
        Id: 24,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(24).jpg").default,
        spinner:"text-info",
         code:'VDPT-7139 ',
    },
    {
        Id: 25,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(25).jpg").default,
        spinner:"text-info",
         code:'VDPT-7140 ',
    },
    {
        Id: 26,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(26).jpg").default,
        spinner:"text-info",
         code:'VDPT-7141 ',
    },
    {
        Id: 27,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(27).jpg").default,
        spinner:"text-info",
         code:'VDPT-7142 ',
    },
    {
        Id: 28,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(28).jpg").default,
        spinner:"text-info",
         code:'VDPT-7142 ',
    },
    {
        Id: 29,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(29).jpg").default,
        spinner:"text-info",
         code:'VDPT-7143 ',
    },
    {
        Id: 30,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(30).jpg").default,
        spinner:"text-info",
         code:'VDPT-7156 ',
    },
    {
        Id: 31,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(31).jpg").default,
        spinner:"text-info",
         code:'VDPT-7256 ',
    },
    {
        Id: 32,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(32).jpg").default,
        spinner:"text-info",
         code:'VDPT-7259 ',
    },
    {
        Id: 33,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(33).jpg").default,
        spinner:"text-info",
         code:'VDPT-7269 ',
    },
    {
        Id: 34,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(34).jpg").default,
        spinner:"text-info",
         code:'VDPT-7271 ',
    },
    {
        Id: 35,
        imgsrc: require( "../images/HANDWOVEN PITLOOM RUGS/(35).jpg").default,
        spinner:"text-info",
         code:'VDPT-10005 ',
    },
    {
        Id: 36,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(36).jpg").default,
        spinner:"text-info",
         code:'VDPT-10006 ',
    },
    {
        Id: 37,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(37).jpg").default,
        spinner:"text-info",
         code:'VDPT-10009 ',
    },
    {
        Id: 38,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(38).jpg").default,
        spinner:"text-info",
         code:'VDPT-10011 ',
    },
    // {
    //     Id: 39,
    //     imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(39).jpg").default,
    //     spinner:"text-info",
    //      code:'VDPT-10011 ',
    // },
    {
        Id: 40,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(40).jpg").default,
        spinner:"text-info",
         code:'VDPT-10015  ',
    },
    {
        Id: 41,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(41).jpg").default,
        spinner:"text-info",
         code:'VDPT-10015',
    },
    {
        Id: 42,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(42).jpg").default,
        spinner:"text-info",
         code:'VDPT-12000  ',
    },
    // {
    //     Id: 43,
    //     imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(43).jpg").default,
    //     spinner:"text-info",
    //      code:'VDPT-12000  ',
    // },
    {
        Id: 44,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(44).jpg").default,
        spinner:"text-info",
         code:'VDPT-12000 ',
    },
    // {
    //     Id: 45,
    //     imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(45).jpg").default,
    //     spinner:"text-info",
    //      code:'VDPT-12000 ',
    // },
    {
        Id: 46,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(46).jpg").default,
        spinner:"text-info",
         code:'VDPT-13001 ',
    },
    {
        Id: 47,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(47).jpg").default,
        spinner:"text-info",
         code:'VDPT-13001',
    },
    {
        Id: 48,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(48).jpg").default,
        spinner:"text-info",
         code:'VDPT-13001  ',
    },
    {
        Id: 49,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(49).jpg").default,
        spinner:"text-info",
         code:'VDPT- 6198 ',
    },
    {
        Id: 50,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(50).jpg").default,
        spinner:"text-info",
         code:'VDPT - 66 ',
    },
    {
        Id: 51,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(51).jpg").default,
        spinner:"text-info",
         code:'VDPT - 70 ',
    },
    {
        Id: 52,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(52).jpg").default,
        spinner:"text-info",
         code:'VDPT - 101 ',
    },
    {
        Id: 53,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(53).jpg").default,
        spinner:"text-info",
         code:'VDPT - 109',
    },
    {
        Id: 54,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(54).jpg").default,
        spinner:"text-info",
         code:'VDPT - 171 ',
    },
    {
        Id: 55,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(55).jpg").default,
        spinner:"text-info",
         code:'VDPT - 801 ',
    },
    {
        Id: 56,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(56).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7119',
    },
    {
        Id: 57,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(57).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7135 ',
    },
    {
        Id: 58,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(58).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7146 ',
    },
    {
        Id: 59,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(59).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7146  ',
    },
    {
        Id: 60,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(60).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7146  ',
    },
    {
        Id: 61,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(61).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7147 ',
    },
    {
        Id: 62,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(62).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7147 ',
    },
    {
        Id: 63,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(63).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7157 ',
    },
    {
        Id: 64,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(64).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7158 ',
    },
    {
        Id: 65,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(65).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7159 ',
    },
    {
        Id: 66,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(66).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7189 ',
    },
    {
        Id: 67,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(67).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7223 ',
    },
    {
        Id: 68,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(68).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7229 ',
    },
    {
        Id: 69,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(69).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7229 ',
    },
    {
        Id: 70,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(70).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7258 ',
    },
    {
        Id: 71,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(71).jpg").default,
        spinner:"text-info",
         code:'VDPT - 7260 ',
    },
    {
        Id: 72,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(72).jpg").default,
        spinner:"text-info",
         code:'VDPT - 10001 ',
    },
    {
        Id: 73,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(73).jpg").default,
        spinner:"text-info",
         code:'VDPT - 10001B ',
    },
    {
        Id: 74,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(74).jpg").default,
        spinner:"text-info",
         code:'VDPT-2061 ',
    },
    {
        Id: 75,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(75).jpg").default,
        spinner:"text-info",
         code:'VDPT-2061 ',
    },
    {
        Id: 76,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(76).jpg").default,
        spinner:"text-info",
         code:'VDPT-2061 ',
    },
    {
        Id: 77,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(77).jpg").default,
        spinner:"text-info",
         code:'VDPT-2061 ',
    },
    {
        Id: 78,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(78).jpg").default,
        spinner:"text-info",
         code:'VDPT-2061 ',
    },
    {
        Id: 79,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(79).jpg").default,
        spinner:"text-info",
         code:'VDPT-2062 ',
    },
    {
        Id: 80,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(80).jpg").default,
        spinner:"text-info",
         code:'VDPT-4008 ',
    },
    {
        Id: 81,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(81).jpg").default,
        spinner:"text-info",
         code:'VDPT-4010 ',
    },
    {
        Id: 82,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(82).jpg").default,
        spinner:"text-info",
         code:'VDPT-5002 ',
    },
    {
        Id: 83,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(83).jpg").default,
        spinner:"text-info",
         code:'VDPT-6065 ',
    },
    {
        Id: 84,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(84).jpg").default,
        spinner:"text-info",
         code:'VDPT-6156 ',
    },
    {
        Id: 85,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(85).jpg").default,
        spinner:"text-info",
         code:'VDPT-6156 ',
    },
    {
        Id: 86,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(86).jpg").default,
        spinner:"text-info",
         code:'VDPT-6184 ',
    },
    {
        Id: 87,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(87).jpg").default,
        spinner:"text-info",
         code:'VDPT-6184 ',
    },
    {
        Id: 88,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(88).jpg").default,
        spinner:"text-info",
         code:'VDPT-6186 ',
    },
    {
        Id: 89,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(89).jpg").default,
        spinner:"text-info",
         code:'VDPT-6225 ',
    },
    {
        Id: 90,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(90).jpg").default,
        spinner:"text-info",
         code:'VDPT-6229 ',
    },
    // {
    //     Id: 91,
    //     imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(91).jpg").default,
    //     spinner:"text-info",
    //      code:'VDPT-6229 ',
    // },
    {
        Id: 92,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(92).jpg").default,
        spinner:"text-info",
         code:'VDPT-7001 ',
    },
    {
        Id: 93,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(93).jpg").default,
        spinner:"text-info",
         code:'VDPT-7001 ',
    },
    {
        Id: 94,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(94).jpg").default,
        spinner:"text-info",
         code:'VDPT-7035  ',
    },
    {
        Id: 95,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(95).jpg").default,
        spinner:"text-info",
         code:'VDPT-7035  ',
    },
    {
        Id: 96,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(96).jpg").default,
        spinner:"text-info",
         code:'VDPT-7043  ',
    },
    {
        Id: 97,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(97).jpg").default,
        spinner:"text-info",
         code:'VDPT-7043  ',
    },
    {
        Id: 98,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(98).jpg").default,
        spinner:"text-info",
         code:'VDPT-7046 ',
    },
    {
        Id: 99,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(99).jpg").default,
        spinner:"text-info",
         code:'VDPT-7047  ',
    },
    {
        Id: 100,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(100).jpg").default,
        spinner:"text-info",
         code:'VDPT-7047  ',
    },
    {
        Id: 101,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(101).jpg").default,
        spinner:"text-info",
         code:'VDPT-7060  ',
    },
    {
        Id: 102,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(102).jpg").default,
        spinner:"text-info",
         code:'VDPT-7060 ',
    },
    {
        Id: 103,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(103).jpg").default,
        spinner:"text-info",
         code:'VDPT-7061  ',
    },
    {
        Id: 104,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(104).jpg").default,
        spinner:"text-info",
         code:'VDPT-7061  ',
    },
    {
        Id: 105,
        imgsrc: require("../images/HANDWOVEN PITLOOM RUGS/(105).jpg").default,
        spinner:"text-info",
         code:'VDPT-7064  ',
    },

   

];
