export default [
    {
        Id: 1,
        imgsrc: require("../images/HANDTUFTED CARPET/(1).jpg").default,
        spinner:"text-primary",
        code:'VDTF-615 ',
    },
    {
        Id: 2,
        imgsrc: require( "../images/HANDTUFTED CARPET/(2).jpg").default,
        spinner:"text-secondary",
        code:'VDTF-616 ',
    },
    {
        Id: 3,
        imgsrc: require( "../images/HANDTUFTED CARPET/(3).jpg").default,
        spinner:"text-success",
        code:'VDTF-617',
    },
    {
        Id: 4,
        imgsrc: require( "../images/HANDTUFTED CARPET/(4).jpg").default,
        spinner:"text-danger",
        code:'VDTF-619(2) ',
    },
    {
        Id: 5,
        imgsrc: require( "../images/HANDTUFTED CARPET/(5).jpg").default,
        spinner:"text-warning",
        code:'VDTF-645 ',
    },
    {
        Id: 6,
        imgsrc: require( "../images/HANDTUFTED CARPET/(6).jpg").default,
        spinner:"text-info",
        code:'VDTF-649 ',
    },
    {
        Id: 7,
        imgsrc: require( "../images/HANDTUFTED CARPET/(7).jpg").default,
        spinner:"text-light",
        code:'VDTF-650 ',
    },
    {
        Id: 8,
        imgsrc: require( "../images/HANDTUFTED CARPET/(8).jpg").default,
        spinner:"text-dark",
        code:'VDTF-651',
    },
    {
        Id: 9,
        imgsrc: require( "../images/HANDTUFTED CARPET/(9).jpg").default,
        spinner:"text-warning",
        code:'VDTF-652(2) ',
    },
    {
        Id: 10,
        imgsrc: require( "../images/HANDTUFTED CARPET/(10).jpg").default,
        spinner:"text-success",
        code:'VDTF-653 ',
    },
    {
        Id: 11,
        imgsrc: require( "../images/HANDTUFTED CARPET/(11).jpg").default,
        spinner:"text-info",
        code:'VDTF-654',
    },
    {
        Id: 12,
        imgsrc: require( "../images/HANDTUFTED CARPET/(12).jpg").default,
        spinner:"text-info",
        code:'VDTF-657 ',
    },
    {
        Id: 13,
        imgsrc: require( "../images/HANDTUFTED CARPET/(13).jpg").default,
        spinner:"text-info",
        code:'VDTF-JT-04 ',
    },
    {
        Id: 14,
        imgsrc: require( "../images/HANDTUFTED CARPET/(14).jpg").default,
        spinner:"text-info",
        code:'VDTF-655 ',
    },
    {
        Id: 15,
        imgsrc: require( "../images/HANDTUFTED CARPET/(15).jpg").default,
        spinner:"text-info",
        code:'VDTF-515 ',
    },
    {
        Id: 16,
        imgsrc: require( "../images/HANDTUFTED CARPET/(16).jpg").default,
        spinner:"text-info",
        code:'VDTF-647(5) ',
    },
    {
        Id: 17,
        imgsrc: require( "../images/HANDTUFTED CARPET/(17).jpg").default,
        spinner:"text-info",
        code:'VDTF-571 ',
    },
    {
        Id: 18,
        imgsrc: require( "../images/HANDTUFTED CARPET/(18).jpg").default,
        spinner:"text-info",
        code:'VDTF-656 ',
    },
    {
        Id: 19,
        imgsrc: require( "../images/HANDTUFTED CARPET/(19).jpg").default,
        spinner:"text-info",
        code:' VDTF-618',
    },
    {
        Id: 20,
        imgsrc: require( "../images/HANDTUFTED CARPET/(20).jpg").default,
        spinner:"text-info",
        code:'VDTF-647(3) ',
    },
    {
        Id: 21,
        imgsrc: require( "../images/HANDTUFTED CARPET/(21).jpg").default,
        spinner:"text-info",
        code:' VDTF-428',
    },
    {
        Id: 22,
        imgsrc: require( "../images/HANDTUFTED CARPET/(22).jpg").default,
        spinner:"text-info",
        code:'VDOD-01 ',
    },
    {
        Id: 23,
        imgsrc: require( "../images/HANDTUFTED CARPET/(23).jpg").default,
        spinner:"text-info",
        code:'VDOD-01 ',
    },
    {
        Id: 24,
        imgsrc: require( "../images/HANDTUFTED CARPET/(24).jpg").default,
        spinner:"text-info",
        code:'VDOD-01 ',
    },
    {
        Id: 25,
        imgsrc: require( "../images/HANDTUFTED CARPET/(25).jpg").default,
        spinner:"text-info",
        code:'VDTF - 05',
    },
    {
        Id: 26,
        imgsrc: require( "../images/HANDTUFTED CARPET/(26).jpg").default,
        spinner:"text-info",
        code:'VDTF - 06 ',
    },
    {
        Id: 27,
        imgsrc: require( "../images/HANDTUFTED CARPET/(27).jpg").default,
        spinner:"text-info",
        code:'VDTF - 18 ',
    },
    {
        Id: 28,
        imgsrc: require( "../images/HANDTUFTED CARPET/(28).jpg").default,
        spinner:"text-info",
        code:'VDTF - 303 ',
    },
    {
        Id: 29,
        imgsrc: require( "../images/HANDTUFTED CARPET/(29).jpg").default,
        spinner:"text-info",
        code:'VDTF - 324 ',
    },
    {
        Id: 30,
        imgsrc: require( "../images/HANDTUFTED CARPET/(30).jpg").default,
        spinner:"text-info",
        code:'VDTF - 332 ',
    },
    {
        Id: 31,
        imgsrc: require( "../images/HANDTUFTED CARPET/(31).jpg").default,
        spinner:"text-info",
        code:'VDTF - 402 ',
    },
    {
        Id: 32,
        imgsrc: require( "../images/HANDTUFTED CARPET/(32).jpg").default,
        spinner:"text-info",
        code:'VDTF - 455(2) ',
    },
    {
        Id: 33,
        imgsrc: require( "../images/HANDTUFTED CARPET/(33).jpg").default,
        spinner:"text-info",
        code:'VDTF - 645 ',
    },
    {
        Id: 34,
        imgsrc: require( "../images/HANDTUFTED CARPET/(34).jpg").default,
        spinner:"text-info",
        code:'VDTF - 1042 ',
    },
    {
        Id: 35,
        imgsrc: require( "../images/HANDTUFTED CARPET/(35).jpg").default,
        spinner:"text-info",
        code:'VDTF- 508 ',
    },
    {
        Id: 36,
        imgsrc: require( "../images/HANDTUFTED CARPET/(36).jpg").default,
        spinner:"text-info",
        code:'VDTF-02(GP) ',
    },
    {
        Id: 37,
        imgsrc: require( "../images/HANDTUFTED CARPET/(37).jpg").default,
        spinner:"text-info",
        code:'VDTF-209 ',
    },
    {
        Id: 38,
        imgsrc: require( "../images/HANDTUFTED CARPET/(38).jpg").default,
        spinner:"text-info",
        code:'VDTF-304 ',
    },
    {
        Id: 39,
        imgsrc: require( "../images/HANDTUFTED CARPET/(39).jpg").default,
        spinner:"text-info",
        code:'VDTF-306  ',
    },
    {
        Id: 40,
        imgsrc: require( "../images/HANDTUFTED CARPET/(40).jpg").default,
        spinner:"text-info",
        code:'VDTF-348 ',
    },
    {
        Id: 41,
        imgsrc: require( "../images/HANDTUFTED CARPET/(41).jpg").default,
        spinner:"text-info",
        code:'VDTF-350 ',
    },
    {
        Id: 42,
        imgsrc: require( "../images/HANDTUFTED CARPET/(42).jpg").default,
        spinner:"text-info",
        code:'VDTF-351 ',
    },
    {
        Id: 43,
        imgsrc: require( "../images/HANDTUFTED CARPET/(43).jpg").default,
        spinner:"text-info",
        code:'VDTF-353 ',
    },
    {
        Id: 44,
        imgsrc: require( "../images/HANDTUFTED CARPET/(44).jpg").default,
        spinner:"text-info",
        code:'VDTF-421 ',
    },
    {
        Id: 45,
        imgsrc: require( "../images/HANDTUFTED CARPET/(45).jpg").default,
        spinner:"text-info",
        code:'VDTF-424  ',
    },
    {
        Id: 46,
        imgsrc: require( "../images/HANDTUFTED CARPET/(46).jpg").default,
        spinner:"text-info",
        code:'VDTF-441 ',
    },
    {
        Id: 47,
        imgsrc: require( "../images/HANDTUFTED CARPET/(47).jpg").default,
        spinner:"text-info",
        code:'VDTF-454 ',
    },
    {
        Id: 48,
        imgsrc: require( "../images/HANDTUFTED CARPET/(48).jpg").default,
        spinner:"text-info",
        code:'VDTF-455',
    },
    {
        Id: 49,
        imgsrc: require( "../images/HANDTUFTED CARPET/(49).jpg").default,
        spinner:"text-info",
        code:'VDTF-501',
    },
    {
        Id: 50,
        imgsrc: require( "../images/HANDTUFTED CARPET/(50).jpg").default,
        spinner:"text-info",
        code:'VDTF-510 ',
    },
    {
        Id: 51,
        imgsrc: require( "../images/HANDTUFTED CARPET/(51).jpg").default,
        spinner:"text-info",
        code:'VDTF-510 ',
    },
    {
        Id: 52,
        imgsrc: require( "../images/HANDTUFTED CARPET/(52).jpg").default,
        spinner:"text-info",
        code:'VDTF-512 ',
    },
    // {
    //     Id: 53,
    //     imgsrc: require( "../images/HANDTUFTED CARPET/(53).jpg").default,
    //     spinner:"text-info",
    //     code:'VDTF-512 ',
    // },
    {
        Id: 54,
        imgsrc: require( "../images/HANDTUFTED CARPET/(54).jpg").default,
        spinner:"text-info",
        code:'VDTF-517 ',
    },
    {
        Id: 55,
        imgsrc: require( "../images/HANDTUFTED CARPET/(55).jpg").default,
        spinner:"text-info",
        code:'VDTF-552 ',
    },
    {
        Id: 56,
        imgsrc: require( "../images/HANDTUFTED CARPET/(56).jpg").default,
        spinner:"text-info",
        code:'VDTF-556',
    },
    {
        Id: 57,
        imgsrc: require( "../images/HANDTUFTED CARPET/(57).jpg").default,
        spinner:"text-info",
        code:'VDTF-559 ',
    },
    {
        Id: 58,
        imgsrc: require( "../images/HANDTUFTED CARPET/(58).jpg").default,
        spinner:"text-info",
        code:'VDTF-560 ',
    },
    {
        Id: 59,
        imgsrc: require( "../images/HANDTUFTED CARPET/(59).jpg").default,
        spinner:"text-info",
        code:'VDTF-561 ',
    },
    {
        Id: 60,
        imgsrc: require( "../images/HANDTUFTED CARPET/(60).jpg").default,
        spinner:"text-info",
        code:'VDTF-565 ',
    },
    {
        Id: 61,
        imgsrc: require( "../images/HANDTUFTED CARPET/(61).jpg").default,
        spinner:"text-info",
        code:'VDTF-567 ',
    },
    {
        Id: 62,
        imgsrc: require( "../images/HANDTUFTED CARPET/(62).jpg").default,
        spinner:"text-info",
        code:'VDTF-568 ',
    },
    {
        Id: 63,
        imgsrc: require( "../images/HANDTUFTED CARPET/(63).jpg").default,
        spinner:"text-info",
        code:'VDTF-569 ',
    },
    {
        Id: 64,
        imgsrc: require( "../images/HANDTUFTED CARPET/(64).jpg").default,
        spinner:"text-info",
        code:'VDTF-573 ',
    },
    {
        Id: 65,
        imgsrc: require( "../images/HANDTUFTED CARPET/(65).jpg").default,
        spinner:"text-info",
        code:'VDTF-574',
    },
    {
        Id: 66,
        imgsrc: require( "../images/HANDTUFTED CARPET/(66).jpg").default,
        spinner:"text-info",
        code:'VDTF-576 ',
    },
    {
        Id: 67,
        imgsrc: require( "../images/HANDTUFTED CARPET/(67).jpg").default,
        spinner:"text-info",
        code:'VDTF-588 ',
    },
    {
        Id: 68,
        imgsrc: require( "../images/HANDTUFTED CARPET/(68).jpg").default,
        spinner:"text-info",
        code:'VDTF-589',
    },
    {
        Id: 69,
        imgsrc: require( "../images/HANDTUFTED CARPET/(69).jpg").default,
        spinner:"text-info",
        code:'VDTF-590 ',
    },
    {
        Id: 70,
        imgsrc: require( "../images/HANDTUFTED CARPET/(70).jpg").default,
        spinner:"text-info",
        code:'VDTF-591 ',
    },
    {
        Id: 71,
        imgsrc: require( "../images/HANDTUFTED CARPET/(71).jpg").default,
        spinner:"text-info",
        code:'VDTF-592',
    },
    {
        Id: 72,
        imgsrc: require( "../images/HANDTUFTED CARPET/(72).jpg").default,
        spinner:"text-info",
        code:'VDTF-593 ',
    },
    {
        Id: 73,
        imgsrc: require( "../images/HANDTUFTED CARPET/(73).jpg").default,
        spinner:"text-info",
        code:'VDTF-594 ',
    },
    {
        Id: 74,
        imgsrc: require( "../images/HANDTUFTED CARPET/(74).jpg").default,
        spinner:"text-info",
        code:'VDTF-594  ',
    },
    {
        Id: 75,
        imgsrc: require( "../images/HANDTUFTED CARPET/(75).jpg").default,
        spinner:"text-info",
        code:'VDTF-594  ',
    },
    {
        Id: 76,
        imgsrc: require( "../images/HANDTUFTED CARPET/(76).jpg").default,
        spinner:"text-info",
        code:'VDTF-595 ',
    },
    {
        Id: 77,
        imgsrc: require( "../images/HANDTUFTED CARPET/(77).jpg").default,
        spinner:"text-info",
        code:'VDTF-596 ',
    },
    {
        Id: 78,
        imgsrc: require( "../images/HANDTUFTED CARPET/(78).jpg").default,
        spinner:"text-info",
        code:'VDTF-597 ',
    },
    {
        Id: 79,
        imgsrc: require( "../images/HANDTUFTED CARPET/(79).jpg").default,
        spinner:"text-info",
        code:'VDTF-598',
    },
    {
        Id: 80,
        imgsrc: require( "../images/HANDTUFTED CARPET/(80).jpg").default,
        spinner:"text-info",
        code:'VDTF-599 ',
    },
    {
        Id: 81,
        imgsrc: require( "../images/HANDTUFTED CARPET/(81).jpg").default,
        spinner:"text-info",
        code:'VDTF-600 ',
    },
    {
        Id: 82,
        imgsrc: require( "../images/HANDTUFTED CARPET/(82).jpg").default,
        spinner:"text-info",
        code:'VDTF-601 ',
    },
    {
        Id: 83,
        imgsrc: require( "../images/HANDTUFTED CARPET/(83).jpg").default,
        spinner:"text-info",
        code:'VDTF-602 ',
    },
    {
        Id: 84,
        imgsrc: require( "../images/HANDTUFTED CARPET/(84).jpg").default,
        spinner:"text-info",
        code:'VDTF-603 ',
    },
    {
        Id: 85,
        imgsrc: require( "../images/HANDTUFTED CARPET/(85).jpg").default,
        spinner:"text-info",
        code:'VDTF-604 ',
    },
    {
        Id: 86,
        imgsrc: require( "../images/HANDTUFTED CARPET/(86).jpg").default,
        spinner:"text-info",
        code:'VDTF-605 ',
    },
    {
        Id: 87,
        imgsrc: require( "../images/HANDTUFTED CARPET/(87).jpg").default,
        spinner:"text-info",
        code:'VDTF-606 ',
    },
    {
        Id: 88,
        imgsrc: require( "../images/HANDTUFTED CARPET/(88).jpg").default,
        spinner:"text-info",
        code:'VDTF-607 ',
    },
    {
        Id: 89,
        imgsrc: require( "../images/HANDTUFTED CARPET/(89).jpg").default,
        spinner:"text-info",
        code:'VDTF-608 ',
    },
    {
        Id: 90,
        imgsrc: require( "../images/HANDTUFTED CARPET/(90).jpg").default,
        spinner:"text-info",
        code:'VDTF-609 ',
    },
    {
        Id: 91,
        imgsrc: require( "../images/HANDTUFTED CARPET/(91).jpg").default,
        spinner:"text-info",
        code:'VDTF-610 ',
    },
    {
        Id: 92,
        imgsrc: require( "../images/HANDTUFTED CARPET/(92).jpg").default,
        spinner:"text-info",
        code:'VDTF-611 ',
    },
    {
        Id: 93,
        imgsrc: require( "../images/HANDTUFTED CARPET/(93).jpg").default,
        spinner:"text-info",
        code:'VDTF-611',
    },
    {
        Id: 94,
        imgsrc: require( "../images/HANDTUFTED CARPET/(94).jpg").default,
        spinner:"text-info",
        code:'VDTF-612 ',
    },
    {
        Id: 95,
        imgsrc: require( "../images/HANDTUFTED CARPET/(95).jpg").default,
        spinner:"text-info",
        code:'VDTF-613 ',
    },
    {
        Id: 96,
        imgsrc: require( "../images/HANDTUFTED CARPET/(96).jpg").default,
        spinner:"text-info",
        code:'VDTF-614 ',
    },

   

];
