export default [
    {
        Id: 1,
        imgsrc: require("../../images/certificate/(1).jpg").default,
        spinner:"text-primary",
    },
    {
        Id: 2,
        imgsrc: require( "../../images/certificate/(2).jpg").default,
        spinner:"text-secondary",
    },
    {
        Id: 3,
        imgsrc: require( "../../images/certificate/(3).jpg").default,
        spinner:"text-success",
    },
    {
        Id: 4,
        imgsrc: require( "../../images/certificate/(8).jpg").default,
        spinner:"text-danger",
    },
    {
        Id: 5,
        imgsrc: require( "../../images/certificate/(5).jpg").default,
        spinner:"text-warning",
    },
    {
        Id: 6,
        imgsrc: require( "../../images/certificate/(6).jpg").default,
        spinner:"text-info",
    },
    {
        Id: 7,
        imgsrc: require( "../../images/certificate/(7).jpg").default,
        spinner:"text-light",
    },
    {
        Id: 8,
        imgsrc: require( "../../images/certificate/(4).jpg").default,
        spinner:"text-dark",
    },
]